<template lang="pug">
div 
    a-drawer(:visible="visible" 
        @close="visible=false"
        :destroyOnClose="true"
        width="80%"
        :title="name")
        PNLChart(:accountId="accountId")
        

    a(@click="handleClick" :disabled="!accountId") 
        my-icon(type="icon-account-book" style="margin-right:5px")
        span {{name}}
</template>

<script>
import PNLChart from '../components/PNLChart.vue';
import MyIcon from '../components/MyIcon';

export default {
    props: ['id', 'name'],
    data() {
        return {
            visible: false,
            account: {},
            
        };
    },
    mounted() {
        if (!this.id) {
            this.$api.request('GET', 'account/detail/'+this.name)
                .then(res => {
                    this.account = res.data;
                });
        }
    },
    methods: {
        handleClick() {
            this.visible = true;
        }
    },
    computed: {
        accountId() {
            if (this.id) {
                return this.id;
            }

            return this.account._id;
        }
    },
    components: {
        PNLChart,
        MyIcon
    }
}
</script>