<template lang="pug">
.page 
    a-tabs 
        a-tab-pane(tab="手工添加" key="manual")
            z-table(:columns="columns" 
                :pageSize="20"
                route="riskInstrument" 
                :editAble="true")
        a-tab-pane(tab="交易系统级" key="tradesystem")
            z-table(:columns="columns" 
                    :pageSize="20"
                    strictRoute="riskInstruments/tradesystem" 
                    :editAble="false"
                    :deleteAble="false"
                    :addAble="false")
        a-tab-pane(tab="策略级" key="strategy")
            z-table(:columns="columns" 
                    :pageSize="20"
                    route="riskInstruments/strategy" 
                    :editAble="false"
                    :deleteAble="false"
                    :addAble="false")
    
</template>

<script>
export default {
    data() {
        return {
            columns: [
                {
                    title: '合约名',
                    addAble: true,
                    searchAble: true,
                    dataIndex: 'code',
                    onFilter: (value, record) => record.code.toString().toLowerCase().includes(value.toLowerCase()),

                },
                {
                    title: '描述',
                    addAble: true,
                    searchAble: true,
                    dataIndex: 'desc',
                    maxWidth: 150,
                    width: 500,
                    editAble: true,
                },
                {
                    title: '创建日期',
                    addAble: false,
                    dataIndex: 'createdAt',
                    sorter: (a, b) => {
                        const dateA = new Date(a.createdAt);
                        const dateB = new Date(b.createdAt);
                        return dateA.getTime() - dateB.getTime();
                    }
                }
            ]
        }
    },
}
</script>