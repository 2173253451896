<template lang="pug">
.page 
    z-table(:columns="columns" route="ctaAccount")
</template>

<script>
export default {
    data() {
        return {
            columns: [
                {
                    title: 'Account',
                    dataIndex: 'account',
                    addAble: true,
                    searchAble: true,
                },
                {
                    title: 'Broker',
                    dataIndex: 'broker',
                    addAble: true,
                    searchAble: true,
                },
                {
                    title: 'URL',
                    dataIndex: 'url',
                    addAble: true,
                    searchAble: true,
                },
                {
                    title: 'Describe',
                    dataIndex: 'desc',
                    addAble: true,
                    searchAble: true,
                }
            ]
        }
    }
}
</script>