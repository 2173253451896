<template lang="pug">
.page
    a-tabs(tab-position="right" v-model="activeKey")
        a-tab-pane(v-for="g in groups" :tab="g" :key="g")
            z-table(:columns="columns" 
                :active="activeKey == g"
                :selectAble="g == today"
                :route="`limitupOrder/${g}/position`" 
                rowKey="instrumentId"
                @selectChange="onSelectChange"
                :selectedRowKeys="selectedRowKeys"
                :autoRefresh="5" 
                :pageSize="30") 

</template>

<script>
import moment from 'moment'
import _ from 'lodash'

export default {
    data() {
        return {
            showMore: false,
            today: '',
            activeKey: '',
            groups: [],
            selectedRowKeys: [],
            columns: [
                {
                    title: '合约',
                    dataIndex: 'instrumentId',
                    addAble: true,
                    editAble: true,
                    searchAble: true,
                    scopedSlots: { customRender: 'inst' },
                    sorter: (a, b) => {
                        if (a.instrumentId > b.instrumentId) return 1
                        else if (a.instrumentId < b.instrumentId) return -1
                        return 0
                    },
                },
                {
                    title: 'Position',
                    dataIndex: 'position',
                    addAble: true,
                    editAble: true,
                    type: 'Number',
                    sorter: (a, b) => a.position - b.position,
                },
                {
                    title: '已成交',
                    dataIndex: 'fillQty',
                    addAble: false,
                    editAble: false,
                    type: 'Number',
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    addAble: false,
                    editAble: false,
                },
                {
                    title: '名称',
                    dataIndex: 'symbol',
                    addAble: false,
                    editAble: false,
                },
                {
                    title: '板块',
                    dataIndex: 'board_type',
                    addAble: false,
                    editAble: false,
                },
                {
                    title: '行业',
                    dataIndex: 'industry_name',
                    addAble: false,
                    editAble: false,
                },
                {
                    title: '创建者',
                    dataIndex: 'createdBy',
                    addAble: true,
                    sorter: (a, b) => {
                        if (a.createdBy > b.createdBy) return 1
                        else if (a.createdBy < b.createdBy) return -1
                        return 0
                    },
                },
                {
                    title: 'ypred_diff',
                    dataIndex: 'ypred_diff',
                    addAble: false,
                    editAble: false,
                    sorter: (a, b) => {
                        if (a.ypred_diff > b.ypred_diff) return 1
                        else if (a.ypred_diff < b.ypred_diff) return -1
                        return 0
                    },
                },
                {
                    title: '创建时间',
                    dataIndex: 'createdAt',
                },
                {
                    title: '更新时间',
                    dataIndex: 'updatedAt',
                },
            ],
        }
    },
    methods: {
        onSelectChange(selectedRowKeys, selectedRows) {
            this.$confirm({
                title: '确定更改？',
                onOk: () => {
                    let data = selectedRows.map(i => {
                        return {
                            inst: i.instrumentId,
                            position: i.position,
                        }
                    })

                    this.$api
                        .request('POST', 'limitupOrder/orders', data)
                        .then(res => {
                            this.selectedRowKeys = selectedRowKeys
                        })
                        .catch(err => {
                            this.$message.error('fail')
                        })
                },
            })
        },

        getSelections() {
            this.$api.request('GET', 'limitupOrder/selection').then(res => {
                this.selectedRowKeys = res.data
            });
        }
    },
    mounted() {
        this.$api.request('GET', 'limitupOrder/groups').then(res => {
            this.groups = res.data
            this.activeKey = this.groups[0]
            this.today = moment().format('YYYY-MM-DD')
        })

        this.getSelections();

        let timer = setInterval(() => {
            this.getSelections();
        }, 5000);

        this.$once('hook:beforeDestroy', () => {
            console.log('这里使用 hook 监听 beforeDestroy 生命')
            clearInterval(timer);
            timer = null;
        });
    },
}
</script>
