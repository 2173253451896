<template lang="pug">
.page 
    .ctl-bar
        a-button(type="primary" style="margin-bottom:10px" @click="exportFile") 导出
    v-chart.chart(:option="option" :autoresize="true")
    a-table(:columns="columns" 
        :rowKey="(record) => record._id"
        :pagination="{total, current, onChange:handlePageChange, pageSize:15}"
        size="small", 
        :dataSource="items")
            
</template>

<script>
import VChart from 'vue-echarts';
import {LineChart} from 'echarts/charts';
import {use} from 'echarts/core'
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    SingleAxisComponent,
    GridComponent,
    AxisPointerComponent
} from 'echarts/components';
import {CanvasRenderer} from 'echarts/renderers';
use([
    CanvasRenderer,
    GridComponent,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    SingleAxisComponent,
    AxisPointerComponent,
    LineChart
]);

export default {
    props: ['strategy', 'name'],
    data() {
        return {
            total: 0,
            current: 1,
            items: [],
            columns: [
                {
                    title: '日期',
                    dataIndex: 'date',
                },
                {
                    title: 'PNL',
                    dataIndex: 'pnl'
                },
            ],
        }
    },
    mounted() {
        this.getList();
    },
    methods: {
        exportFile() {
            this.$api.download(`strategy/${this.strategy}/exportPnls`, {}, 'order')
                .then(res => {
                    let url = window.URL.createObjectURL(new Blob([res.data], { type: 'text/csv' }));
                    let docUrl = document.createElement('a');
                    docUrl.href = url;
                    docUrl.setAttribute('download', `${this.name}_pnl.csv`);
                    document.body.appendChild(docUrl);
                    docUrl.click();
                    window.URL.revokeObjectURL(url);
                    docUrl.remove();
                })
        },
        handlePageChange(page) {
            this.current = page;
            this.getList();
        },
        getList() {
            this.$api.request('GET', `strategy/${this.strategy}/pnl`, {}, 'order')
                .then(res => {
                    this.items = res.data;
                });
        }
    },
    computed: {
        option() {
            return {
                xAxis: {
                    type: 'category',
                    data: this.items.map(i => i.date),
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        data: this.items.map(i => i.pnl),
                        type: 'line'
                    }
                ]
            }
        }
    },
    components: {
        VChart
    },
}
</script>

<style lang="less" scoped>
.chart {
    height: 300px;
    // margin: 5px;
}
</style>