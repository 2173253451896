<template lang="pug">
    .page 
        a-modal(v-model="addVisible"
            @ok="submitAdd"
            title="新增任务")
            a-form
                a-form-item(label="任务类型")
                    a-radio-group(
                        default-value="python"
                        button-stype="solid"
                        v-model="addForm.type"
                    )
                        a-radio-button(value="python") python
                        a-radio-button(value="c++") c++
                a-form-item(label="路径")
                    a-input(v-model="addForm.path")
                a-form-item(label="参数")
                    a-input(v-model="addForm.args")
        a-drawer(title="变量编辑"
            @close="varVisible=false"
            destroyOnClose
            width="800"
            :visible="varVisible")
            VarEdit(:workerId="varEditWorkId")
        a-table(:dataSource="workerItems" 
            size="small"
            :columns="workerColumns")
            template(slot="operate" slot-scope="record")
                a-button(type="primary" 
                    size="small" 
                    style="margin:0 5px"
                    @click="handleAdd(record)") 增加任务
                a-button(type="primary" 
                    size="small" 
                    v-if="workerOnlines[record._id]"
                    style="margin:0 5px") 一键启动
                a-button(type="danger" 
                    size="small" 
                    v-if="workerOnlines[record._id]"
                    style="margin:0 5px") 一键停止
                a-button(type="primary" 
                    size="small" 
                    style="margin:0 5px") 日志下载
                a-button(type="primary" 
                    @click="handleVarEdit(record._id)"
                    style="margin:0 5px"
                    size="small") 变量编辑
            template(slot="isonline" slot-scope="record")
                .online-yes(v-if="workerOnlines[record._id]")
                .online-no(v-else)
            template(slot="expandedRowRender" slot-scope="record")
                a-card
                    a-card-grid(:style="{width: focusIds.includes(task._id) ? '100%' : '33.3%'}" 
                        :key="task._id"
                        v-for="task in record.tasks")
                        TaskPane(:type="task.type"
                            @delete="getData"
                            @focus="handleFocus(task._id)"
                            :args="task.args"
                            :taskId="task._id"
                            :path="task.path")
                    //- a-card-grid(style="width: 33.3%")
                    //-     TaskPane(name="test" command="python -u test.py" status="fail" taskId="2")
                    //- a-card-grid(style="width: 33.3%")
                    //-     TaskPane(name="test" command="python -u test.py" status="fail" taskId="3")
</template>

<script>
import moment from 'moment'
import {mapState} from 'vuex'

import TaskPane from '../components/TaskPane'
import VarEdit from '../components/VarEdit'

export default {
    data() {
        return {
            focusIds: [],
            addVisible: false,
            varVisible: false,
            varEditWorkId: null,
            addForm: {
                workerId: '',
                type: 'python',
                args: '',
                path: '',
            },
            workerItems: [],
            workerColumns: [
                // {
                //     title: 'Name',
                //     key: 'name',
                //     dataIndex: 'name',
                // },
                {
                    title: 'IP',
                    key: 'ip',
                    dataIndex: 'ip'
                },
                {
                    title: 'Is Online',
                    key: 'isOnline',
                    // dataIndex: 'isOnline',
                    align: 'center',
                    scopedSlots: { customRender: 'isonline' },
                },
                {
                    title: 'Connect At',
                    key: 'lastConnectAt',
                    dataIndex: 'lastConnectAt'
                },
                {
                    title: 'Operate',
                    key: 'operate',
                    align: 'center',
                    scopedSlots: { customRender: 'operate' },
                }
            ]
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        handleFocus(taskId) {
            if (this.focusIds.includes(taskId)) {
                this.focusIds = this.focusIds.filter(i => i!=taskId);
            }
            else {
                this.focusIds.push(taskId);
            }
        },
        handleAdd(record) {
            this.addVisible = true;
            this.addForm.workerId = record._id;
        },
        submitAdd() {
            this.$api.request('POST', 'worker/task', this.addForm)
                .then(() => {
                    this.getData();
                    this.addVisible = false;
                })
        },
        handleVarEdit(workerId) {
            this.varEditWorkId = workerId;
            this.varVisible = true;
        },
        getData() {
            this.$api.request('GET', 'workers')
            .then(res => {
                this.workerItems = res.data.map(i => {
                    let s = {};
                    s[i._id] = i.isOnline;
                    console.log(s);
                    this.$store.commit('worker/setWorkerOnlines', s);

                    return {
                        ...i,
                        lastConnectAt: moment(i.lastConnectAt).format('lll'),
                        disConnectAt: moment(i.disConnectAt).format('lll')
                    };
                });
            });
            
        }
    },
    computed: {
        ...mapState('worker', ['workerOnlines']),
    },
    components: {
        TaskPane,
        VarEdit
    }
}
</script>

<style lang="less" scoped>
.online-yes {
    width: 50px;
    height: 20px;
    border-radius: 10px;
    background: #3cf53c;
    margin: auto;
}

.online-no {
    width: 50px;
    height: 20px;
    border-radius: 10px;
    background: gray;
    margin: auto;
}
</style>