import io from 'socket.io-client'

class IO {
    constructor() {
        this.io = io({
            path: '/api/v2/socket/worker/'
        }, {
            "transports": ['websocket']
        });

        // this.io.on('notify', data => {
        //     this.$notify[data.type]({
        //         message: data.title,
        //         description: data.content,
        //         duration: data.duration,
        //         onClose: () => {
        //             console.log(data.msgId);
        //         }
        //     });
        // });

        this.io.on('connect', () => {
            // console.log('io connect');
            this.register(this.userId);
        });

        // this.io.on('stdout', data => {
        //     // console.log(data);
        //     this.$store.commit('worker/addTaskOutput', data);
        // });

        // this.io.on('stderr', data => {
        //     this.$store.commit('worker/addTaskOutput', data);
        // });



    }

    register(userId) {
        this.userId = userId;
        this.io.emit('register', userId);
    }

    init(store, notify) {
        this.$store = store;
        this.$notify = notify;
    }
}

const _io = new IO();

export default {
    install: function(Vue) {
        Vue.prototype.$io = _io;
    }
}