<template lang="pug">
div 
    a-drawer(:visible="visible"
        :destroyOnClose="true"
        width="80%"
        :title="name"
        @close="visible=false")
        a-tabs(@tabClick="refresh") 
            a-tab-pane(tab="当前持仓" key="position")
                span(style="margin-right:10px")
                    a-upload(name="set_position" 
                        accept=".txt"
                        :showUploadList="false"
                        :beforeUpload="beforeUpload"
                        :multiple="false")
                        a-button(type="primary")
                            a-icon(type="upload") 
                            span 目标持仓下单
                a-upload(name="init_position" 
                    accept=".json"
                    :showUploadList="false"
                    :beforeUpload="beforeInitUpload"
                    :multiple="false")
                    a-button(type="default")
                        a-icon(type="upload") 
                        span 目标持仓初始化
                a-card(style="margin: 10px 0" title="当前持仓" size="small" )
                    PositionView(:positions="strategy.positions")
            a-tab-pane(tab="下单记录" key="order")
                OrderHistory(:strategy="id" ref="orderHist" :name="name")
            a-tab-pane(tab="成交记录" key="trade")                
                TradeHistory(:strategy="id" ref="tradeHist" :name="name")
            a-tab-pane(tab="PNL记录" key="pnl")                
                PnlHistory(:strategy="id" ref="pnlHist" :name="name")
    a(@click="handleDetail" style="font-size:16px;") 
        my-icon(type="icon-send")
        span {{name}}
</template>

<script>
import OrderHistory from '../components/order/OrderHistory.vue';
import TradeHistory from '../components/order/TradeHistory.vue';
import PositionView from '../components/order/PositionView.vue';
import PnlHistory from '../components/order/PnlHistory.vue';
import MyIcon from '../components/MyIcon';

export default {
    props: ['id', 'name', 'weight'],
    data() {
        return {
            visible: false,
            strategy: {},
        }
    },
    mounted() {
        
    },
    methods: {
        refresh(tab) {
            if (tab == 'order' && this.$refs.orderHist) {
                this.$refs.orderHist.getList();
            }
            else if (tab == 'trade' && this.$refs.tradeHist) {
                this.$refs.tradeHist.getList();
                // this.getCurrent();
            }
            else if (tab == 'pnl' && this.$refs.pnlHist) {
                this.$refs.pnlHist.getList();
                // this.getCurrent();
            }
            else if (tab == 'position') {
                this.getDetail();
            }
        },
        handleDetail() {
            this.visible = true;
            this.getDetail();
        },
        async beforeUpload(file) {
            let fr = new FileReader();

            let p = new Promise((resolve, reject) => {
                fr.onload = () => {
                    resolve(fr.result);
                };
                fr.onerror = reject;
            });
            
            fr.readAsText(file);

            let config = await p;
            let positions = {

            };

            config.trim().split('\n')
                .map(i => {
                    let j = i.trim().split(/[\t|\s|,]+/);
                    let instrument = j[0].trim();
                    let target = parseInt(j[1].trim());

                    positions[instrument] = target;
                });
            this.$store.commit('order/addTargetPosition', {
                name: this.name,
                _id: this.id,
                weight: this.weight,
                positions
            });
            return false;
        },
        async beforeInitUpload(file) {
            let fr = new FileReader();

            let p = new Promise((resolve, reject) => {
                fr.onload = () => {
                    resolve(fr.result);
                };
                fr.onerror = reject;
            });
            
            fr.readAsText(file);

            let config = await p;
            let positions = JSON.parse(config);
            try {
                let res = await this.$api.request('POST', `strategy/${this.id}/targetPositionInit`, {positions}, 'order');
                console.log(res.data);
                this.getDetail();
                this.$message.success("初始化仓位成功");
            } catch (error) {
                this.$message.error("初始化仓位失败");
            }
            
        },
        getDetail() {
            this.$api.request('GET', 'strategy/'+this.id, {}, 'order')
                .then(res => {
                    this.strategy = res.data;
                })
        }
    },
    components: {
        OrderHistory,
        TradeHistory,
        PositionView,
        PnlHistory,
        MyIcon,
    }
}
</script>