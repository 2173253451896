<template lang="pug">
    .page 
        //- a-card(title="大商所")
        //-     a-card-grid(:style="{width: fouse==ins?'100%':'50%',transition: 'none !important'}" @click="toggleFouse(ins)" v-for="ins in instruments_dalian" :key="ins")
        //-         OptionChart(:symbol="ins" )
            //- a-card-grid(style="width:25%")
            //-     OptionChart(symbol="c2201")
            //- a-card-grid(style="width:25%")
            //-     OptionChart(symbol="i2201")
            //- a-card-grid(style="width:25%")
            //-     OptionChart(symbol="pg2201")
            //- a-card-grid(style="width:25%")
            //-     OptionChart(symbol="pp2201")
            //- a-card-grid(style="width:25%")
            //-     OptionChart(symbol="v2201")
            //- a-card-grid(style="width:25%")
            //-     OptionChart(symbol="l2201")
            //- a-card-grid(style="width:25%")
            //-     OptionChart(symbol="p2201")
            //- a-card-grid(style="position:absolute;width:100%;height:100%")
        OptionChart(symbol="510050")
        OptionChart(symbol="510300")
        //- OptionChart(symbol="510300")
        //- OptionChart(symbol="i2201")
        //- OptionChart(symbol="m2201")
</template>

<script>
import OptionChart from '../components/OptionChart.vue';

export default {
    data() {
        return {
            instruments_dalian: [                
                'm2205',
                'c2205',
                'i2205',
                'pg2205',
                'pp2205',
                'v2205',
                'l2205',
                'p2205'
            ],
            fouse: ''
        };
    },
    methods: {
        toggleFouse(key) {
            if (this.fouse == key) this.fouse = '';
            else this.fouse = key;
        } 
    },
    components: {
        OptionChart
    },
   
}
</script>

<style lang="less" scoped>
.ant-card {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
</style>