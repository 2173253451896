<template lang="pug">
.page 
    a-card(size="small")
        a-row(gutter="16")
            a-col(span="6" v-for="i in IHData" :key="i.code")
                a-statistic(
                    :value="Math.round((i.index - i.future)*10)/10")
                    template(slot="title")
                        Instrument(:code="i.code")
                        span _at {{i.time}}

    
    a-card(size="small")
        a-row(gutter="16")
            a-col(span="6" v-for="i in IFData" :key="i.code")
                a-statistic(
                    :value="Math.round((i.index - i.future)*10)/10")
                    template(slot="title")
                        Instrument(:code="i.code")
                        span _at {{i.time}}
    
    a-card(size="small")
        a-row(gutter="16")
            a-col(span="6" v-for="i in ICData" :key="i.code")
                a-statistic(
                    :value="Math.round((i.index - i.future)*10)/10")
                    template(slot="title")
                        Instrument(:code="i.code")
                        span _at {{i.time}}

    a-card(size="small")
        a-row(gutter="16")
            a-col(span="6" v-for="i in IMData" :key="i.code")
                a-statistic(
                    :value="Math.round((i.index - i.future)*10)/10")
                    template(slot="title")
                        Instrument(:code="i.code")
                        span _at {{i.time}}
</template>

<script>
import _ from 'lodash';
import Instrument from '../components/Instrument.vue';

export default {
    data() {
        return {
            socket: null,
            IFData: [],
            IMData: [],
            ICData: [],
            IHData: [],
        }
    },
    mounted() {
        this.getData();

        let timer = setInterval(() => {
            this.getData();
        }, 3 * 1000);

        this.$once('hook:beforeDestroy', () => {
            console.log('这里使用 hook 监听 beforeDestroy 生命')
            clearInterval(timer);
            timer = null;
        });
    },
    // created() {
    //     this.socket = new WebSocket(`ws://${window.location.host}/api/v3/indexDiff`);

    //     this.socket.addEventListener('message', (event) => {
    //         let d = JSON.parse(event.data);
    //         this.IFData = _.sortBy(d['IF'], 'code');
    //         this.IMData = _.sortBy(d['IM'], 'code');
    //         this.ICData = _.sortBy(d['IC'], 'code');
    //         this.IHData = _.sortBy(d['IH'], 'code');
    //         console.log(event.data);
    //         this.socket.send('');
    //     })
    // },
    // beforeDestroy() {
    //     if (this.socket) {
    //         this.socket.close();
    //     }
    // },
    methods: {
        getData() {
            this.$api.request('GET', 'indexDiff')
                .then(res => {
                    let d = res.data;
                    this.IFData = _.sortBy(d['IF'], 'code');
                    this.IMData = _.sortBy(d['IM'], 'code');
                    this.ICData = _.sortBy(d['IC'], 'code');
                    this.IHData = _.sortBy(d['IH'], 'code');
                    console.log(event.data);
                    this.socket.send('');
                });
        }
    },
    components: {Instrument}
}
</script>