<template lang="pug">
div 
    a-drawer(:visible="visible"
        :destroyOnClose="true"
        width="50%"
        :title="account"
        @close="visible=false")
        StrategyItem(v-for="s in strategys" :key="s._id" :id="s._id" :name="s.name" :weight="s.weight" style="margin-bottom:5px")
    a(@click="handleDetail" style="font-size:16px;") 
        my-icon(type="icon-account-book" style="margin-right:5px")
        span {{account}}
        span(style="color:red;margin-left:5px") {{address}}
</template>

<script>
import MyIcon from '../components/MyIcon';
import StrategyItem from './StrategyItem.vue';

export default {
    props: ['id', 'address', 'account'],
    data() {
        return {
            visible: false,
            strategys: []
        }
    },
    mounted() {

    },
    methods: {
        handleDetail() {
            this.visible = true;
            this.getDetail();
        },
        getDetail() {
            this.$api.request('GET', 'tradeAccount/'+this.id, {}, 'order')
                .then(res => {
                    this.strategys = res.data.strategys;
                })
        }
    },
    components: {
        MyIcon,
        StrategyItem,
    }
}
</script>