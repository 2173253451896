<template lang="pug">
    div
        a-modal(v-model="groupVisible"
            @ok="submitAddGroup"
            title="增加品种")
            a-form
                a-form-item(label="名称")
                    a-input(v-model="groupName")
        a-modal(v-model="varVisible"
            @ok="submitAddVar"
            title="增加变量")
            a-form
                a-form-item(label="变量名")
                    a-input(v-model="newVar.name")
                a-form-item(label="值")
                    a-input(v-if="newVar.type == 'string'"
                        v-model="newVar.value")
                    a-input-number(v-else 
                        style="width:100%;"
                        v-model="newVar.value")
                a-form-item(label="数据类型")
                    a-select(v-model="newVar.type")
                        a-select-option(value="number") Number
                        a-select-option(value="string") String
        a-button(type="primary"
            @click="groupVisible=true"
            style="margin: 0 10px 10px 0") 增加品种
        a-popconfirm(title="确定删除该品种?"
            @confirm="submitDelGroup(selectGroupId)")
            a-button(type="danger") 删除该品种
        a-tabs(v-model="selectGroupId")
            a-tab-pane(v-for="g in groups"
                :closable="true"
                :tab="g.group"
                :key="g._id")
                a-table(:columns="columns"
                    size="small"
                    :dataSource="g.values")
                    template(slot="operate" slot-scope="record")
                        a-button(type="primary"
                            :disabled="!record.hadUpdate"
                            style="margin: 0 5px;"
                            @click="submitUpdateVar(g._id, record)"
                            size="small") 保存
                        a-button(
                            :disabled="!record.hadUpdate"
                            @click="handleCancelEditVar(record)"
                            style="margin: 0 5px;"
                            size="small") 取消
                        a-button(type="danger"
                            style="margin: 0 5px;"
                            @click="submitDelVar(g._id, record.name)"
                            size="small") 删除

                    template(slot="value" slot-scope="val,record")
                        a-input(v-if="record.type=='string'" 
                            class="value-input"
                            @change="handleEditVar(record)"
                            size="small"
                            v-model="record.value")
                        a-input-number(v-else 
                            @change="handleEditVar(record)"
                            class="value-input"
                            size="small"
                            v-model="record.value")
                a-button(type="primary"
                    size="small"
                    @click="varVisible=true;newVar.groupId=g._id;"
                    style="margin-top: 10px") 增加变量
</template>

<script>
export default {
    props: ['workerId'],
    data() {
        return {
            groupVisible: false,
            varVisible: false,
            groupName: '',
            newVar: {
                name: '',
                value: 0,
                type: 'number',
                groupId: '',
            },
            groups: [],
            selectGroupId: '',
            columns: [
                {
                    dataIndex: 'name',
                    title: '变量名',
                    key: 'key'
                },
                {
                    dataIndex: 'value',
                    title: '值',
                    key: 'value',
                    scopedSlots: {
                        customRender: 'value',
                    }
                },
                {
                    dataIndex: 'type',
                    title: '数据类型',
                    key: 'type'
                },
                {
                    title: '操作',
                    key: 'operate',
                    scopedSlots: {
                        customRender: 'operate',
                    }
                }
            ]
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        handleEditVar(record) {
            record.hadUpdate = true;
        },
        handleCancelEditVar() {
            this.getData();
        },
        submitAddGroup() {
            this.$api.request('post', 'worker/group/'+this.workerId, {
                group: this.groupName
            })
            .then(() => {
                this.getData();
                this.groupName = '';
                this.groupVisible = false;
            });
        },
        submitDelGroup(groupId) {
            this.$api.request('delete', 'worker/group/'+groupId)
            .then(() => {
                this.getData();
            });
        },
        submitDelVar(groupId, name) {
            this.$api.request('delete', 'worker/variable/'+groupId, {
                name: name,
                groupId: groupId
            })
            .then(() => {
                this.getData();
            })
        },
        submitAddVar() {
            this.$api.request('post', 'worker/variable/'+this.newVar.groupId, {
                name: this.newVar.name,
                type: this.newVar.type,
                value: this.newVar.value,
            })
            .then(() => {
                this.getData();
                this.newVar = {
                    name: '',
                    value: 0,
                    type: 'number',
                    groupId: ''
                };
                this.varVisible = false;
            });

        },
        submitUpdateVar(groupId, record) {
            this.$api.request('PUT', 'worker/variable/'+groupId, {
                name: record.name,
                type: record.type,
                value: record.value,
            })
            .then(() => {
                this.getData();
            })
        },
        getData() {
            this.$api.request('Get', 'worker/variable/'+this.workerId)
            .then(res => {
                this.groups = res.data.map(i => {
                    return {
                        ...i,
                        values: i.values.map(j => ({...j, hadUpdate: false})),
                    };
                });
            });
        }
    }
}
</script>

<style lang="less" scoped>
.value-input {
    width: 100px;
}
</style>