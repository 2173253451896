<template lang="pug">
.page 
    a-modal(v-model="addVisible"
        @close="addVisible=false"
        @ok="submitAdd"
        width="60%"
        title="创建")
        a-form(:label-col="{ span: 5 }"
            :wrapper-col="{ span: 17 }")
            a-form-item(label="Name" style="margin-bottom: 5px")
                a-input(v-model="addContent.productName")
            a-form-item(label="Account" style="margin-bottom: 5px")
                a-input(v-model="addContent.account")
            a-form-item(label="Broker" style="margin-bottom: 5px")
                a-input(v-model="addContent.broker")
            a-form-item(label="Hedge Account" style="margin-bottom: 5px")
                a-input(v-model="addContent.hedge_account")
            a-form-item(label="Hedge Broker" style="margin-bottom: 5px")
                a-input(v-model="addContent.hedge_broker")
            a-form-item(label="Hedge Pos" style="margin-bottom: 5px")
                a-input(v-model="addContent.hedge_pos")
            a-form-item(label="URL" style="margin-bottom: 5px")
                a-input(v-model="addContent.url")
            a-form-item(label="T0 URL" style="margin-bottom: 5px")
                a-input(v-model="addContent.t0Url")
            a-form-item(label="Old Name" style="margin-bottom: 5px")
                a-input(v-model="addContent.name_old")
            a-form-item(label="描述" style="margin-bottom: 5px")
                a-input(v-model="addContent.desc")
    a-modal(v-model="upVisible"
        @close="upVisible=false"
        @ok="submitUpdate"
        width="60%"
        title="编辑")
        a-form(:label-col="{ span: 5 }"
            :wrapper-col="{ span: 17 }")
            a-form-item(label="Name" style="margin-bottom: 5px")
                a-input(v-model="upContent.productName")
            a-form-item(label="Account" style="margin-bottom: 5px")
                a-input(v-model="upContent.account")
            a-form-item(label="Broker" style="margin-bottom: 5px")
                a-input(v-model="upContent.broker")
            a-form-item(label="Status" style="margin-bottom: 5px")
                a-radio-group(button-style="solid" v-model="upContent.status")
                    a-radio-button(value="active") Active
                    a-radio-button(value="pending") Pending
                    a-radio-button(value="inactive") Inactive
            a-form-item(label="Hedge Account" style="margin-bottom: 5px")
                a-input(v-model="upContent.hedge_account")
            a-form-item(label="Hedge Broker" style="margin-bottom: 5px")
                a-input(v-model="upContent.hedge_broker")
            a-form-item(label="Hedge Pos" style="margin-bottom: 5px")
                a-input(v-model="upContent.hedge_pos")
            a-form-item(label="URL" style="margin-bottom: 5px")
                a-input(v-model="upContent.url")
            a-form-item(label="T0 URL" style="margin-bottom: 5px")
                a-input(v-model="upContent.t0Url")
            a-form-item(label="Old Name" style="margin-bottom: 5px")
                a-input(v-model="upContent.name_old")
            a-form-item(label="描述" style="margin-bottom: 5px")
                a-input(v-model="upContent.desc")

    .ctl-bar 
        a-button(type="primary" @click="handleCreate") 创建
        a-checkbox-group(v-model="statusSelected" 
            style="margin:5px 20px"
            :options="statusOptions")

    a-table(:columns="columns"
            :rowKey="(record) => record._id"
            :loading="loading"
            :pagination="{pageSize: 20}"
            size="small"
            :dataSource="filterItems")

        

        template(slot="operate" slot-scope="record")
            a-popconfirm(title="确认删除？" @confirm="submitDel(record._id)")
                a 
                    a-icon(type="delete")
            a-divider(type="vertical")
            a(@click="handleUpdate(record)") 
                a-icon(type="edit")
</template>

<script>
export default {
    data() {
        return {
            statusOptions: ['active', 'pending', 'inactive'],
            statusSelected: ['active'],
            addVisible: false,
            upVisible: false,
            addContent: {
                productName: '',
                account: '',
                broker: '',
                hedge_account: '',
                hedge_broker: '',
                hedge_pos: '',
                url: '',
                t0Url: '',
                name_old: '',
                desc: '',
            },
            upContent: {
                id: '',
                productName: '',
                status: 'active',
                account: '',
                broker: '',
                hedge_account: '',
                hedge_broker: '',
                hedge_pos: '',
                url: '',
                t0Url: '',
                name_old: '',
                desc: '',
            },
            items: [],
            loading: false,
            columns: [
                {
                    title: 'Name',
                    dataIndex: 'productName',
                    sorter: (a, b) => {
                        if (a.productName > b.productName) return 1;
                        else if (a.productName < b.productName) return -1;
                        return 0;
                    },
                },
                {
                    title: 'Account',
                    dataIndex: 'account',
                    sorter: (a, b) => {
                        if (a.productName > b.productName) return 1;
                        else if (a.productName < b.productName) return -1;
                        return 0;
                    },
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    scopedSlots: {
                        customRender: 'status'
                    },
                    
                },
                
                {
                    title: 'Hedge Account',
                    dataIndex: 'hedge_account',
                    sorter: (a, b) => {
                        if (a.productName > b.productName) return 1;
                        else if (a.productName < b.productName) return -1;
                        return 0;
                    },
                },
                {
                    title: 'Hedge Broker',
                    dataIndex: 'hedge_broker',
                    sorter: (a, b) => {
                        if (a.productName > b.productName) return 1;
                        else if (a.productName < b.productName) return -1;
                        return 0;
                    },
                },
                {
                    title: 'Hedge Pos',
                    dataIndex: 'hedge_pos'
                },
                {
                    title: 'URL',
                    dataIndex: 'url',
                },
                {
                    title: 'T0 URL',
                    dataIndex: 't0Url',
                },
                // {
                //     title: 'Old Name',
                //     dataIndex: 'name_old',
                //     sorter: (a, b) => {
                //         if (a.productName > b.productName) return 1;
                //         else if (a.productName < b.productName) return -1;
                //         return 0;
                //     },
                // },
                {
                    title: 'Broker',
                    dataIndex: 'broker',
                    sorter: (a, b) => {
                        if (a.productName > b.productName) return 1;
                        else if (a.productName < b.productName) return -1;
                        return 0;
                    },
                },
                {
                    title: 'Note',
                    dataIndex: 'desc',
                },
                {
                    title: '操作',
                    scopedSlots: {
                        customRender: 'operate'
                    }
                }
            ],
        };
    },
    mounted() {
        this.getList();
    },
    methods: {
        submitAdd() {
            this.$api.request('POST', 'cbondAccount', this.addContent, 'v2')
                .then(() => {
                    this.getList();
                    this.addVisible = false;
                })
                .catch(() => {
                    this.$message.error("增加失败");
                });
        },
        submitUpdate() {
            this.$api.request('PUT', 'cbondAccount/'+this.upContent.id, this.upContent, 'v2')
                .then(() => {
                    this.getList();
                    this.upVisible = false;
                })
                .catch(() => {
                    this.$message.error("更新失败");
                });
        },
        submitDel(id) {
            this.$api.request('DELETE', 'cbondAccount/'+id, {}, 'v2')
            .then(() => {
                this.getList();
            })
            .catch((r) => {
                console.log(r.response.data);
                this.$message.error("删除失败");
            });
        },
        handleCreate() {
            this.addVisible = true;
        },
        handleUpdate(record) {
            this.upVisible = true;
            this.upContent = {
                id: record._id,
                productName: record.productName,
                account: record.account,
                url: record.url,
                t0Url: record.t0Url,
                desc: record.desc,
                name_old: record.name_old,
                broker: record.broker,
                hedge_account: record.hedge_account,
                hedge_broker: record.hedge_broker,
                hedge_pos: record.hedge_pos,
                status: record.status,
            };
        },
        getList() {
            if (this.loading == true) return;
            this.loading = true;
            this.$api.request('GET', 'cbondAccounts', {}, 'v2')
            .then(res => {
                this.items = res.data;
                this.loading = false;
            })
        }
    },
    computed: {
        filterItems() {
            return this.items.filter(i => {
                return this.statusSelected.includes(i.status);
            });
        }
    }
}
</script>