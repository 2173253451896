<template lang="pug">
div 
    OptionRt(
        :calls="calls1"
        :puts="puts1"
        :currentCode="currentCode"
        :current="current"
        :spot="spot"
        @select="handleSelect"
    )
    OptionRt(
        :calls="calls2"
        :puts="puts2"
        :currentCode="currentCode"
        :spot="spot"
        :current="current"
        @select="handleSelect"
    )
    //- a-row
    //-     a-col(:span="12")
    //-         a-table(:columns="callColumns"
    //-             rowKey="trading_code"
    //-             :rowClassName="rowClass"   
    //-             :pagination="{pageSize:10, hideOnSinglePage: true}"
    //-             size="small" 
    //-             :customRow="customRow"
    //-             :dataSource="calls")
    //-     a-col(:span="12") 
    //-         a-table(:columns="putColumns"
    //-             rowKey="trading_code"
    //-             :rowClassName="rowClass"
    //-             :pagination="{pageSize:10, hideOnSinglePage: true}"
    //-             size="small" 
    //-             :customRow="customRow"
    //-             :dataSource="puts")
    a-row(:gutter="20")
        a-col(:span="8")
            a-card(title="计算器")
                OptionCal(:trading_code="currentCode" 
                    :spotPrice="spotPrice"
                    :current="current"
                    :delta="current.delta" 
                    :price="current.last")
        a-col(:span="16")
            OptionPositionCal(:etfCode="etfCode"   
                :spotCode="spotName"
                :etfPrice="etf"
                :name="name"
                @changeFutures="changeFutures"
                :dynamicData="dynamicData"
                :spotPrice="spotPrice"
                :seleted="currentCode")
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import OptionCal from './OptionCal.vue';
import OptionPositionCal from './OptionPositionCal.vue';
import OptionRt from './OptionRt.vue';

const HOST = 'http://192.168.1.39:7002';

export default {
    props: ['spotName', 'etfCode', 'isActive', 'name'],
    data() {        
        return {
            dynamicData: {

            },
            futureCodes: [],
            calls1: [],
            puts1: [],
            calls2: [],
            puts2: [],
            currentCode: '',
            current: {
                trading_code: '',
                delta: 0,
                last: 0,
            },
            spotPrice: 0.1,
            spot: {
                last: 0,
                ask: 0,
                bid: 0,
            },
            etf: {
                last: 0,
                ask: 0,
                bid: 0,
            },
            

        }
    },
    mounted() {
        this.getData();
        let timer = setInterval(() => {
            if (this.isActive) 
                this.getData();
        }, 1500);

        this.$once('hook:beforeDestroy', () => {
            console.log('这里使用 hook 监听 beforeDestroy 生命')
            clearInterval(timer);
            timer = null
        });
    },
    methods: {
        changeFutures(value) {
            this.futureCodes = value;
        },
        handleSelect(record) {
            this.currentCode = record.trading_code;
            this.current = {...record};
        },
        async getPrice(code) {
            let res = await axios.get(`/price/${code}`);
            return {
                data: {
                    ask: Number(res.data.a1),
                    bid: Number(res.data.b1),
                    last: Number(res.data.last),
                }
            };
        },
        getData() {
            this.futureCodes.forEach(item => {
                this.getPrice(item)
                .then(r => {
                    this.dynamicData[item] = r.data;
                });
            });

            this.getPrice(this.spotName)
            .then(r => {
                this.spot = r.data;
                this.spotPrice = r.data.last;
                this.dynamicData[this.spotName] = r.data;
            });

            this.getPrice(this.etfCode)
            .then(r => {
                this.etf = r.data;
                this.dynamicData[this.etfCode] = r.data;
            });

            this.$api.request('GET', `rt_bs/${this.spotName}`)
                .then(r => {
                    this.calls1 = _.chain(r.data[0])
                        .filter(i => i.option_type == 'C')
                        .sortBy('strike_price')
                        .value();
                   
                    this.puts1 = _.chain(r.data[0])
                        .filter(i => i.option_type == 'P')
                        .sortBy('strike_price')
                        .value();

                    this.calls2 = _.chain(r.data[1])
                        .filter(i => i.option_type == 'C')
                        .sortBy('strike_price')
                        .value();
                   
                    this.puts2 = _.chain(r.data[1])
                        .filter(i => i.option_type == 'P')
                        .sortBy('strike_price')
                        .value();
                    
                    r.data[0].map(i => {
                        this.dynamicData[i.trading_code] = i;
                        if (i.trading_code == this.current.trading_code) {
                            this.current = {
                                ...i,
                            };
                        }
                    });

                    r.data[1].map(i => {
                        this.dynamicData[i.trading_code] = i;
                        if (i.trading_code == this.current.trading_code) {

                            this.current = {
                                ...i,
                            };
                        }
                    });
                });

            this.dynamicData = {...this.dynamicData};
        },
        
        
    },
    components: {
        OptionCal,
        OptionPositionCal,
        OptionRt
    }
}
</script>

<style lang="less">


</style>