<template lang="pug">
a-popover(:title="code" :destroyTooltipOnHide="true" @visibleChange="onChange" placement="rightBottom")
    a {{code}}
    template(slot="content")
        a-spin.chart(v-if="loading")
        v-chart.chart(:option="option" :autoresize="true" v-else)
</template>

<script>
import VChart, {THEME_KEY} from 'vue-echarts';
import axios from 'axios';
import _ from 'lodash';
// import Chart from './Chart.vue';

export default {
    props: ['code'],
    data() {
        return {
            loading: false,
            prices: [],
            xlabels: [],
            limitUp: null,
            limitDown: null,
        };
    },
    methods: {
        onChange(visible) {
            if (visible) {
                this.loading = true;
                axios.get(`/rtchart/${this.code}`)
                    .then(res => {
                        let data = _.filter(res.data.value, i => i[1] > 0);

                        this.limitDown = res.data.limitDown;
                        this.limitUp = res.data.limitUp;

                        if (res.data.preClose) {
                            this.prices = data.map(i => (i[2] / res.data.preClose - 1)*100);
                        }

                        
                        this.xlabels = data.map(i => i[0]);
                        this.loading = false;
                    })
            }
            // console.log(visible);
        }
    },
    computed: {
        option() {
            let options = {
                grid: [
                    {height: '80%', top: '3%', width: '87%'},
                ],  
                xAxis: {
                    type: 'category',
                    data: this.xlabels,
                    gridIndex: 0,
                },
                yAxis: {
                    type: 'value',
                    scale: true,
                    gridIndex: 0,
                    axisLabel: {
                        formatter: '{value} %'
                    }
                    
                },
                series: [
                    {
                        data: this.prices,
                        type: 'line',
                        showSymbol: false,
                        animation: false,
                        
                    }
                ]
            };

            // if (this.limitDown) {
            //     options.series.push({
            //         data: this.prices.map(i => this.limitDown),
            //         type: 'line',
            //         showSymbol: false,
            //         animation: false,
            //     });
            // }

            // if (this.limitUp) {
            //     options.series.push({
            //         data: this.prices.map(i => this.limitUp),
            //         type: 'line',
            //         showSymbol: false,
            //         animation: false,
            //     });
            // }

            return options;
        }
    },
    components: {
        VChart
    },
    mounted() {

    }
}
</script>

<style lang="less" scoped>
.chart {
    height: 250px;
    width: 400px;
    // margin: 5px;
}
</style>