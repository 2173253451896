<template lang="pug">
div
    a-modal(v-model="newFactor.visible"
        @ok="submitAddFactor"
        title="添加因子")
        a-form 
            a-form-item(label="ID")
                a-input(v-model="newFactor.id")
            a-form-item(label="weight")
                a-radio-group(v-model="newFactor.weight" size="small")
                    a-radio-button(:value="1") 1
                    a-radio-button(:value="-1") -1

    a-tabs 
        a-tab-pane(tab="因子列表" key="factor")
            a-button(type="primary" 
                style="margin-bottom:10px" 
                size="small"
                @click="handleAddFactor" ) 
                a-icon(type="plus")
                span 添加

            a-upload(name="factors"
            :beforeUpload="beforeUpload"
            :multiple="false"
            :showUploadList="false"
            accept=".csv")
                a-button(type="primary" 
                    size="small"
                    style="margin-left:10px")
                    a-icon(type="upload") 
                    span 导入

            a-button(type="primary" 
                @click="exportCsv"
                size="small"
                style="margin-left:10px")
                a-icon(type="download") 
                span 导出
            
            a-button(type="primary" 
                style="margin-left:10px" 
                size="small"
                @click="getData" ) 
                a-icon(type="redo")
                span 刷新

            a-button(type="danger"
                style="margin-left:10px"
                size="small")
                a-icon(type="delete")
                span 删除

            a-input-search(placeholder="input search text" 
                style="width: 180px;margin-left:10px" 
                size="small"
                v-model="search")

            a-table(
                size="small"
                rowKey="id"
                :loading="loading"
                :dataSource="computedFactors"
                :columns="factorColumns"
                :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            )
                template(slot="weight" slot-scope="w, record")
                    a-radio-group(v-model="w" size="small" @change="changeWeight(record._id, $event)")
                        a-radio-button(:value="1") 1
                        a-radio-button(:value="-1") -1
                template(slot="operate" slot-scope="record")
                    a-popconfirm(title="确认删除？" @confirm="submitDel(record.id)")
                        a
                            a-icon(type="delete" class="operate-icon")     

        a-tab-pane(tab="运行" key="daily")
            div(stype="margin-bottom:20px")
                a-date-picker(v-model="runDate")
                a-button(type="primary" 
                    @click="runDaily"
                    :disabled="!runDate"
                    style="margin: 0 10px") Run
            
            div 
                a-range-picker(v-model="runRange")
                a-button(type="primary" 
                    @click="runRangeDate"
                    :disabled="!runRange"
                    style="margin: 0 10px") Run
            


    //- a-card(style="margin-bottom:5px" title="IC记录")
    //-     a-table

    //- a-card(title="backtest记录")
    //-     a-table
</template>

<script>
import _ from 'lodash';

export default {
    props: ['group'],
    data() {
        return {
            search: '',
            selectedRowKeys: [],
            factors: [],
            loading: false,
            newFactor: {
                visible: false,
                weight: 1,
                id: '',
            },
            factorColumns: [
                {
                    title: 'ID',
                    dataIndex: 'id',
                },
                {
                    title: '因子名',
                    dataIndex: 'name',
                },
                {
                    title: '权重',
                    dataIndex: 'weight',
                    scopedSlots: { customRender: 'weight' },
                },
                {
                    title: '操作',
                    scopedSlots: { customRender: 'operate' },
                }
            ],
            items: [],
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    fixed: 'left',
                    width: 50,
                },
                {
                    title: '行情等级',
                    dataIndex: 'level',
                    filters: [
                        { text: 'Daily', value: 'Daily' },
                        { text: 'Minute', value: 'Minute' },
                        { text: 'Tick', value: 'Tick' },
                        { text: 'Level2', value: 'Level2' },
                    ],
                },
                {
                    title: '权重',
                    dataIndex: 'weight',
                    scopedSlots: { customRender: 'weight' },
                },
                {
                    title: '最后提交者',
                    dataIndex: 'committer_name'
                },
                {
                    title: '更新时间',
                    dataIndex: 'updatedAt',
                },
            ],
            runDate: null,
            runRange: null,
        };
        
    },
    mounted() {
        this.getData();
    },
    methods: {
        runDaily() {
            let start_date = this.runDate.format('YYYY-MM-DD');
    
            this.$api.request('POST', `factorgroup/${this.group}/run`, {
                start_date: start_date,
                end_date: start_date,
            }, 'task')
            .then(() => {
                this.$message.success('start success');
            });
        },
        runRangeDate() {
            let start_date = this.runRange[0].format('YYYY-MM-DD');
            let end_date = this.runRange[1].format('YYYY-MM-DD');
            
            this.$api.request('POST', `factorgroup/${this.group}/run`, {
                start_date: start_date,
                end_date: end_date,
            }, 'task')
            .then(() => {
                this.$message.success('start success');
            });
        },
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys;
        },
        exportCsv() {
            let content = this.computedFactors.map(item => {
                return [item.id, item.name, item.weight].join(',')
            }).join('\n');
            content = 'id,name,weight\n' + content;
            const file = new Blob([content], { type: 'text/plain' });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(file);
            link.download = this.group + ".csv";
            link.click();
            URL.revokeObjectURL(link.href);
        },
        async beforeUpload(file) {
            let fr = new FileReader();
            let p = new Promise((resolve, reject) => {
                fr.onload = () => {
                    resolve(fr.result);
                };
                fr.onerror = reject;
            });
            
            fr.readAsText(file);
            let factors = await p;
            let header = null;
            this.loading = true;
            await Promise.all(factors.split(/\n/).map(async i => {
                console.log(i);
                if (!header) {
                    header = i.split(',');
                }
                else {
                    let value = i.split(',');
                    let o = _.zipObject(header, value);
                    console.log(o);
                    if (o.id) {
                        await this.$api.request('PUT', `factorgroup/${this.group}/add`, 
                            {
                                id: parseInt(o.id),
                                weight: o.weight == -1 ? -1 : 1,
                            }, 'task');
                    }

                }
                
            }));
            
            this.getData();
            return false;
        },
        handleAddFactor() {
            this.newFactor = {
                visible: true,
                weight: 1,
                id: '',
            };
        },
        submitDel(id) {
            this.$api.request('PUT', `factorgroup/${this.group}/del`, {
                id
            }, 'task')
            .then(() => {
                this.getData();
            });
        },
        submitAddFactor() {
            this.$api.request('PUT', `factorgroup/${this.group}/add`, 
            {
                id: parseInt(this.newFactor.id),
                weight: this.newFactor.weight,
            }, 'task')
                .then(() => {
                    this.newFactor.visible = false;
                    this.getData();
                });
        },
        getData() {
            this.loading = true;
            this.$api.request('GET', 'factorgroup/' + this.group, {}, 'task')
                .then(res => {
                    this.loading = false;
                    this.factors = res.data.factors;
                });
        },
    },
    computed: {
        computedFactors() {
            return this.factors.filter(item => {
                if (this.search) {
                    if (!item.name.toLocaleLowerCase().includes(this.search.toLocaleLowerCase().trim()) && (this.search != item.id)) {
                        return false;
                    }
                }

                return true;
            });
        }
    }
}
</script>

<style scoped lang="less">
.factor-item {
    // background: gray;
    // border: solid 1px;
    margin: 1px;
    padding: 5px 0;
    display: flex;
    flex-direction: row;
    margin-bottom: 1px;
    .factor-name {
        flex: 1;
    }
    .factor-weight {
        width: 10px;
    }  
}
</style>