<template lang="pug">
.page 
    a-tabs 
        a-tab-pane(tab="节点" key="worker")
            a-table(:columns="workerColumns" 
                :dataSource="workers"
                size="small"
            )
                div(slot="bar" 
                    style="margin-right:15px;max-width: 150px; color:#000"
                    slot-scope="current,record")
                    a-progress(:percent="current" 
                        size="small"
                        :width="50"
                        type="dashboard"
                        status="normal"
                        :stroke-color="current > 80 ? '#fb6565' : '#84f184'"
                    )

        a-tab-pane(tab="任务" key="task")
            a-table(:columns="taskColumns" 
                :dataSource="tasks"
                size="small")
</template>

<script>
import axios from 'axios';
import moment from 'moment';
export default {
    data() {
        return {
            workers: [],
            tasks: [],

            workerColumns: [
                {
                    title: '节点',
                    dataIndex: 'name',
                },
                
                {
                    title: 'CPU使用率',
                    dataIndex: 'cpuUsage',
                    scopedSlots: { customRender: 'bar' },
                },
                {
                    title: '内存使用率',
                    dataIndex: 'memoryUsage',
                    scopedSlots: { customRender: 'bar' },
                },
                {
                    title: '硬盘使用率',
                    dataIndex: 'diskUsage',
                    scopedSlots: { customRender: 'bar' },
                },
                {
                    title: '版本',
                    dataIndex: 'version',
                },
                {
                    title: '时间',
                    dataIndex: 'lastCheck',
                }
            ],
            taskColumns: [
                {
                    title: '函数名',
                    dataIndex: 'func_name',
                },
                {
                    title: '主机名称',
                    dataIndex: 'worker_name',
                },
                {
                    title: '优先级',
                    dataIndex: 'level',
                },
                {
                    title: '创建时间',
                    dataIndex: 'createdAt',
                },
                {
                    title: '运行时间',
                    dataIndex: 'startAt',
                    // render: (text) => {
                    //     return `${moment().diff(moment(text), 'seconds')}秒`
                    // }
                }
            ],
        }
    },
    mounted() {
        this.getData();

        let timer = setInterval(() => {
            // if (this.isActive) 
                this.getData();
        }, 2000);

        this.$once('hook:beforeDestroy', () => {
            console.log('这里使用 hook 监听 beforeDestroy 生命')
            clearInterval(timer);
            timer = null
        });
    },
    methods: {
        getData() {
            let host = '192.168.1.21';

            if (location.host.includes('10.8.0.110')) { 
                host = '10.8.0.146';
            }

            axios.get(`/workers`)
                .then(res => {
                    this.workers = res.data.map(i => {
                        return {
                            ...i,
                            lastCheck: moment(i.lastCheck).format('lll')
                        }
                    });
                })

            axios.get(`/tasks`)
                .then(res => {
                    this.tasks = (res.data.running || []).map(i => {
                        return {
                            ...i,
                            createdAt: moment(i.createdAt).format('lll'),
                            startAt: moment().diff(moment(i.startAt), 'seconds'),
                        }
                    });
                })
        }
    }
}
</script>