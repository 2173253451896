<template lang="pug">
.page 
    a-modal(v-model="addVisible"
        @close="addVisible=false"
        @ok="submitAdd"
        title="创建交易账号")
        a-form(:label-col="labelCol"
            :wrapper-col="wrapperCol")
            a-form-item(label="账号")
                a-input(v-model="addContent.account")
            a-form-item(label="地址")
                a-input(v-model="addContent.address")
            a-form-item(label="类型")
                a-input(v-model="addContent.type")
            a-form-item(label="描述")
                a-input(v-model="addContent.desc")
    a-modal(v-model="upVisible"
        @close="upVisible=false"
        @ok="submitUpdate"
        title="更新交易账号")
        a-form(:label-col="labelCol"
            :wrapper-col="wrapperCol")
            a-form-item(label="账号")
                a-input(v-model="upContent.account")
            a-form-item(label="地址")
                a-input(v-model="upContent.address")
            a-form-item(label="类型")
                a-input(v-model="upContent.type")
            a-form-item(label="描述")
                a-input(v-model="upContent.desc")
    .ctl-bar
        a-button(type="primary" @click="handleCreate") 创建
        a-input-search(placeholder="search"
            v-model="filterValue"
            style="margin-left:10px;width:200px")
    a-table(:columns="columns" 
        :rowKey="(record) => record._id"
        size="small" 
        :pagination="{pageSize:15}"
        :dataSource="filterItems")
        template(slot="account" slot-scope="item,record")
            TradeAccountItem(:id="record._id" :address="record.address" :account="item")
        template(slot="operate" slot-scope="record")
            a-popconfirm(title="确认删除？" @confirm="submitDel(record._id)")
                a 
                    a-icon(type="delete")
            a-divider(type="vertical")
            a(@click="handleUpdate(record)") 
                a-icon(type="edit")
</template>

<script>
import moment from 'moment';
import TradeHistory from './TradeHistory.vue';
import OrderHistory from './OrderHistory.vue';
import TradeAccountItem from '../../links/TradeAccountItem.vue';

export default {
    data() {
        return {
            labelCol: {
                xs: {span: 24},
                sm: {span: 5}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 18}
            },
            columns: [
                {
                    title: '账号',
                    dataIndex: 'account',
                    scopedSlots: {
                        customRender: 'account'
                    }
                },
                {
                    title: '地址',
                    dataIndex: 'address',
                },
                {
                    title: '类型',
                    dataIndex: 'type',
                },
                {
                    title: '描述',
                    dataIndex: 'desc',
                },
                {
                    title: '创建时间',
                    dataIndex: 'createdAt',
                },
                {
                    title: '更新时间',
                    dataIndex: 'updatedAt',
                },
                {
                    title: '操作',
                    scopedSlots: {
                        customRender: 'operate'
                    }
                }
            ],
            items: [],
            addVisible: false,
            addContent: {
                account: '',
                address: '',
                type: 'ctp',
                desc: '', 
            },
            upVisible: false,
            upContent: {
                id: '',
                account: '',
                address: '',
                type: 'ctp',
                desc: '', 
            },
            filterValue: ''
        }
    },
    mounted() {
        this.getList();
    },
    methods: {
        getList() {
            this.$api.request('GET', 'tradeAccount/list', {}, 'order')
                .then(res => {
                    this.items = res.data.map(d => ({
                        ...d,
                        createdAt: moment(d.createdAt).format('lll'),
                        updatedAt: moment(d.updatedAt).format('lll'),
                    }));
                })
        },
        handleCreate() {
            this.addVisible = true;
        },
        handleUpdate(record) {
            
            this.upContent = {
                id: record._id,
                account: record.account,
                address: record.address,
                type: record.type,
                desc: record.desc,
            };
            this.upVisible = true;
        },
        submitAdd() {
            this.$api.request('POST', 'tradeAccount/create', this.addContent, 'order')
                .then(() => {
                    this.getList();
                    this.addVisible = false;
                })
                .catch(() => {
                    this.$message.error("增加账号失败");
                });
        },
        submitDel(id) {
            this.$api.request('DELETE', 'tradeAccount/'+id, {}, 'order')
                .then(() => {
                    this.getList();
                })
                .catch((r) => {
                    console.log(r.response.data);
                    this.$message.error(`删除账号失败: ${r.response.data}`);
                });
        },
        submitUpdate() {
            this.$api.request('POST', 'tradeAccount/update/'+this.upContent.id, this.upContent, 'order')
                .then(() => {
                    this.getList();
                    this.upVisible = false;
                })
                .catch(() => {
                    this.$message.error("更新账号失败");
                });
        }
    },
    computed: {
        filterItems() {
            return this.items.filter(item => {
                if (!this.filterValue) return true;

                let f = this.filterValue.trim().toUpperCase();
                return item.account.toUpperCase().includes(f) || item.address.toUpperCase().includes(f) || item.desc.toUpperCase().includes(f);
            });
        }
    },
    components: {
        TradeHistory,
        OrderHistory,
        TradeAccountItem,
    }
}
</script>