const Perms = [
    {
        value: 'worker',
        label: '控制台',
        color: 'green',
        iconType: 'icon-tasks',
        iconCustom: true,
    },
    {
        value: 'dashboard',
        label: '指标',
        color: 'green',
        iconType: 'dashboard',
    },
    // {
    //     value: 'optionParam',
    //     label: '期权指标',
    //     color: 'cyan',
    //     iconType: 'icon-send',
    //     iconCustom: true,
    //     invisiable: true,
    // },
    {
        value: 'stockFactors',
        label: 'Daily因子',
        color: 'blue',
        iconType: 'fund',
    },
    {
        value: 'cluster',
        label: '集群管理',
        color: 'red',
        iconType: 'cluster'
    },
    {
        value: 'onlineOrder',
        label: '下单管理',
        color: 'purple',
        iconType: 'icon-send',
        iconCustom: true,
    },
    {
        value: 'onlineOrderPlus',
        label: '策略管理root',
        color: 'purple',
        invisiable: true,
    },
    // {
    //     value: 'accountInfo',
    //     label: '账号信息管理',
    //     color: 'red',
    // },
    
    {
        value: 'strat_cbondT0_all',
        label: 'Cbond T0 ALL product',
        color: 'purple',
        invisiable: true,
    },
    {
        value: 'strat_Option1',
        label: '期权1',
        color: 'purple',
        iconType: 'icon-send',
        iconCustom: true,
    },
    {
        value: 'strat_cbondT0',
        label: 'Cbond T0',
        color: 'purple',
        iconType: 'icon-adjust',
        iconCustom: true,
    },
    {
        value: 'cbondAccount',
        label: 'Cbond账号',
        color: 'red',
        iconType: 'icon-account-book',
        iconCustom: true,
    },
    {
        value: 'ctaAccount',
        label: 'CTA账号',
        color: 'red',
        iconType: 'icon-account-book',
        iconCustom: true,
    },
    {
        value: 'news',
        label: '新闻',
        color: 'purple',
        iconType: 'message'
    },
    {
        value: 'limitUpOrder',
        label: '打板策略',
        color: 'purple',
        iconType: 'icon-account-book',
        iconCustom: true,
    },
    // {
    //     value 'folder',
    //     label: '打板文件',
    //     color: 'red',
    //     iconType: 'folder',
    // },
    {
        value: 'hkOption',
        label: 'HK期权',
        color: 'purple',
        iconType: 'icon-send',
        iconCustom: true,
    },
    {
        value: 'hkOptionRead',
        label: 'HK期权只读',
        color: 'purple',
        iconType: 'icon-send',
        iconCustom: true,
    },
    {
        value: 'hkOptionAdmin',
        label: 'HK期权Admin',
        color: 'purple',
        iconType: 'icon-send',
        iconCustom: true,
        invisiable: true,
    },
    {
        value: 'strategy',
        label: '策略组',
        color: 'purple',
        iconType: 'carry-out'
    },
    {
        value: 'pnlMonitor',
        label: 'Pnl监控',
        color: 'red',
        iconType: 'monitor',
    },
    {
        value: 'riskInstrument',
        label: '风险合约',
        color: 'red',
        iconType: 'filter',
    },
    {
        value: 'globalRisk',
        label: '全局风控',
        color: 'red',
        iconType: 'control',
    },
    {
        value: 'admin',
        label: '用户管理',
        color: 'red',
        iconType: 'team'
    },
    {
        value: 'adminFile',
        label: '文件权限',
        color: 'red',
        iconType: 'folder'
    },
    {
        value: 'display',
        label: '对外展示',
        color: 'red',
        iconType: 'team',
        invisiable: true,
    },
    {
        value: 'toolsDoc',
        label: 'Tools API Doc',
        color: 'yellow',
        iconType: 'api',
    },
];

export default {
    install: function(Vue) {
        Vue.prototype.$perms = Perms;
    }
}