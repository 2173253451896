<template lang="pug">
div 
    a-row
        a-col(:span="12")
            a-table(:columns="callColumns"
                rowKey="trading_code"
                :rowClassName="rowClass"   
                :pagination="{pageSize:10, hideOnSinglePage: true}"
                size="small" 
                :customRow="customRow"
                :dataSource="calls")
        a-col(:span="12") 
            a-table(:columns="putColumns"
                rowKey="trading_code"
                :rowClassName="rowClass"
                :pagination="{pageSize:10, hideOnSinglePage: true}"
                size="small" 
                :customRow="customRow"
                :dataSource="puts")
</template>

<script>
export default {
    props: ['calls', 'puts', 'currentCode', 'spot', 'current'],
    data() {
        let columns = [
            {
                title: '合约',
                dataIndex: 'trading_code',
            },
            {
                title: 'Delta MA',
                dataIndex: 'delta_ma',
            },
            {
                title: 'Delta',
                dataIndex: 'delta',
            },
            
            {
                title: 'Delta2',
                dataIndex: 'py_delta',
            },
            {
                title: 'GAMMA',
                dataIndex: 'gamma',
            },
            // {
            //     title: 'Vega',
            //     dataIndex: 'vega',
            // },
            {
                title: 'price',
                dataIndex: 'last',
            },
            {
                title: '执行价',
                dataIndex: 'strike_price',
            },
        ];

        return {
            callColumns: columns,
            putColumns: columns, 
            // puts: [],
            // calls: [],
        }
    },
    methods: {
        rowClass(record) {
            let selectedClass = record.trading_code == this.currentCode ? ' seleted' : ''

            if (record.option_type == 'C') {
                if (record.strike_price < this.spot.last) {
                    return 'buy-row' + selectedClass;
                }
                else {
                    return 'sell-row' + selectedClass;
                }
            }

            if (record.option_type == 'P') {
                if (record.strike_price < this.spot.last) {
                    return 'sell-row' + selectedClass;
                }
                else {
                    return 'buy-row' + selectedClass;
                }
            }
        },
        customRow(record) {
            return {
                on: {
                    click: (event) => {
                        this.$emit('select', record);
                        // this.currentCode = record.trading_code;
                        // this.current = {...record};
                    }
                }
            }
        },
    }
}
</script>

<style lang="less">

.buy-row {
    background-color: rgba(255, 0, 0, 0.1);
    font-size: 14px;
    line-height: 14px;
}

.sell-row {
    background-color: rgba(0, 255, 0, 0.1);
    line-height: 14px;
}

.seleted {
    background-color: blue;
    color: white;
    
}

</style>