<template lang="pug">
.main
    .nav-header
        NavHeader
    .main-content(:class="$mq")
        keep-alive
            router-view.main-page(v-if="$route.meta.keepAlive")
        router-view.main-page(v-if="!$route.meta.keepAlive")
</template>

<script>
import {mapState} from 'vuex';
import NavHeader from '../components/NavHeader.vue';
export default {
    mounted() {
        // if (!this.me) {
        //     this.$router.replace('/login');
        // }
    },
    computed: {
        ...mapState('user', ['me'])
    },
    components: {
        NavHeader
    }
}
</script>

<style lang="less" scoped>
.main {
    height: 100%;
    .nav-header {
        height: 40px;
        // background-color: gray;
    }

    .main-content {
        display: flex;
        flex-direction: row;
        // height: 100%;
        height: calc(~"100% - 40px");
        

        .main-page {
            flex: 1;
        }
    }
    .main-content.sm {
        flex-direction: column;
    }
}

</style>