<template lang="pug">
.container
    
    a-card 
        a-card-grid(style="width:12.5%" v-for="[k,v] in posPairs" :key="k" :title="k")
            h3.pos-title {{k}}
                .pos-panel
                    a-row.pos-item(v-for="item,index in v" 
                        :style="{background: item.position > 0 ? '#eb0a0a24': '#00800045'}"
                        :key="index")
                        a-col(:span="12") 
                            .pos-label {{item.instrument}}
                        a-col(:span="12") {{item.position}}
</template>

<script>
import _ from 'lodash';

export default {
    props: ['positions', 'filterIns'],
    data() {
        return {
        };
    },
    computed: {
        posPairs() {
            return _.chain(this.positions)
                .toPairs()
                .map(([k,v]) => {
    
                    return [k, _.filter(v, i => {
                        if (this.filterIns) {
                            return i.position != 0 && i.instrument.includes(this.filterIns);
                        }
                        else {
                            return i.position != 0;
                        }
                        
                    })]
                })
                .value()
        }
    }
}
</script>

<style lang="less" scoped>
.pos-panel {
    height: 415px;
    overflow: auto;
    color: black;
    font-size: 14px;
    font-weight: 400;
}
.pos-item {
    margin: 2px 0;
}
.pos-label {
    font-weight: bold;    
}
.pos-title {
    font-size: 16px;
    font-weight: bold;
    color: #1890ff;
}

</style>