<template lang="pug">
.page 
    a-card(size="small" v-for="item in targetPositions" :key="item._id" :title="item.name" style="margin-bottom:10px")
        PositionView(:positions="item.positions")
    .drawer-button
        a-button(type="primary" 
            :loading="isTrading"
            @click="send")            
            span 下单
        a-button(type="default" 
            style="margin-left:20px"
            @click="cancel") 
            span 取消
</template>

<script>
import {mapState} from 'vuex';
import PositionView from './PositionView.vue';
import _ from 'lodash';

export default {
    data() {
        return {
            isTrading: false,
        };
    },
    methods: {
        async send() {
            this.isTrading = true;
            await Promise.all(this.targetPositions.map(async item => {
                let positions = _.clone(item.positions);
                if (item.weight && (item.weight!=1)) {
                    positions = _.chain(positions)
                        .toPairs()
                        .map(([key, val]) => [key,  parseInt(val*item.weight)])
                        .fromPairs()
                        .value();
                }
                let res = await this.$api.request('POST', `strategy/${item._id}/targetPositionOrder`, {positions}, 'order');
                let new_positions = res.data;

                let syncFinish = true;

                _.toPairs(positions).map(([key, val]) => {
                    let curr = new_positions[key] || 0;
                    if (val != curr) syncFinish = false;
                });

                if (syncFinish) {
                    this.$store.commit('order/rmTargetPosition', item._id);
                }
            }));
            this.isTrading = false;
        },
        cancel() {
            this.$store.commit('order/clearTargetPositions')
        }
    },
    components: {
        PositionView
    },
    computed: {
        ...mapState('order', ['targetPositions'])
    },
}
</script>