<template lang="pug">
.app
    .loading-page(v-if="loading")
        a-spin(style="width:100%;height:100%" )

    router-view(v-else)
</template>

<script>
export default {
    data() {
        return {
            loading: true
        }
    },  
    mounted() {
        this.$api.init(this.$store, this.$message, this.$router);
        this.$io.init(this.$store, this.$notification);
        this.$api.request('GET', 'me')
        .then(res => {
            // console.log(this.$route.path);
            this.loading = false;
            this.$router.replace(this.$route.path);
            this.$store.commit('user/setMe', res.data);
            this.$io.register(res.data._id);
        })
        .catch(() => {
            this.loading = false;
            this.$store.commit('user/setMe', null);
            this.$router.replace('/login');
        })
    }
}
</script>

<style lang="less" scoped>
.loading-page {
    text-align: center;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    height: 100%;
    // margin-bottom: 20px;
    padding: 300px 50px;
    // margin: 20px 0;
}
</style>