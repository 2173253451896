<template lang="pug">
div(style="max-width:500px;margin:auto;padding-top:100px") 
    a-form(:label-col="{ span: 5 }" 
        :form="form"
        :wrapper-col="{ span: 12 }")
        a-form-item(label="用户名")
            a-input(v-decorator="['name', { rules: [{ required: true, message: 'Please input your username!' }] }]"
                placeholder="用户名")
        a-form-item(label="密码")
            a-input(v-decorator="['password', { rules: [{ required: true, message: 'Please input your password!' }] }]"
                type="password"
                placeholder="密码")
        a-form-item(:wrapper-col="{ span: 12, offset: 5 }")
            a-button(type="primary" @click="login" style="width:100%") 登录
</template>

<script>
export default {
    data() {
        return {
            form: this.$form.createForm(this, {name: 'login'})
        }
    },
    methods: {
        login() {
            this.form.validateFields((errors, value) => {
                if(!errors) {
                    this.$api.request('POST', 'login', value)
                        .then(() => {
                            this.$api.request('GET', 'me')
                                .then(res => {
                                    this.$router.replace('/main');
                                    this.$store.commit('user/setMe', res.data);
                                });
                        })
                        .catch(() => {
                            this.$message.error("错误的账号或密码")
                        });
                }
            });
        }
    }
}
</script>