<template lang="pug">
.page 
    z-table(:columns="columns" 
        
        route="globalRisk")
</template>

<script>
export default {
    data() {
        return {
            columns: [
                {
                    title: '名称',
                    addAble: true,
                    editAble: false,
                    searchAble: true,
                    dataIndex: 'name',
                },
                {
                    title: '值',
                    addAble: true,
                    editAble: true,
                    dataIndex: 'value',
                    type: 'Number',
                },
                {
                    title: '时间',
                    addAble: false,
                    dataIndex: 'createdAt',
                }
            ]
        }
    }
}
</script>