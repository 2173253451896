const state = {
    me: null,
};

const getters = {
    isLogined: state => !!state.me,
    isExternalDisplay: state => state.me.perms.includes('display'),
};

const mutations = {
    setMe(state, user) {
        console.log(user);
        state.me = user;
    },
};

const actions = {

};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}

