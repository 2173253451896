<template lang="pug">
.page 
    FactorList(type="stock")
</template>

<script>
import FactorList from './FactorList.vue';

export default {
    components: {
        FactorList
    }
}
</script>
