<template lang="pug">
.container
    a-tabs(tab-position="left")
        a-tab-pane(v-for="[strategyId,diff] in strategyIds"
            size="small"
            :key="strategyId")
            a-badge(:count="diff" slot="tab" dot)
                span {{strategyId}}
            a-input-search(v-model="filterIns" 
                style="max-width:200px;margin:10px 0;"
                placeholder="filter")
            a-table(:dataSource="filteredOrders[strategyId]"
                size="small"
                rowKey="instrument"
                :pagination="{pageSize:15}"
                :columns="columns")
                OrderPosition(slot="instrument"
                    :instrumentId="text"
                    :strategyId="strategyId"
                    slot-scope="text, record")
                
                span(slot="target"
                    slot-scope="text,record")
                    span(style="background:red;padding:0 20px;color:white;border-radius:5px;" v-if="record.target!=record.position") {{text}}
                    span(v-else style="padding:0 20px") {{text}}
            //- div(v-for="e in orders[strategyId]"
            //-     :key="e.instrument")
            //-     span(style="color:black;font-size:16px;padding-right:20px;") {{e.instrument}}
            //-     span {{e.target}}
        
</template>

<script>
import _ from 'lodash';
import OrderPosition from '../links/OrderPosition.vue';

export default {
    props: ['orders', 'onlyDisplay'],
    data() {
        let columns = [
            {
                title: '合约名',
                dataIndex: 'instrument',
                sorter: (a, b) => a.instrument > b.instrument,
                scopedSlots: {
                    customRender: 'instrument'
                }
            },
            {
                title: '当前持仓',
                dataIndex: 'position',
                sorter: (a, b) => a.position - b.position
            }
        ];

        if (!this.onlyDisplay) {
            columns.push({
                title: '目标仓位',
                dataIndex: 'target',
                scopedSlots: {
                    customRender: 'target'
                }
                
            });

            columns.push({
                title: '差值',
                dataIndex: 'diff',
                sorter: (a, b) =>  Math.abs(a.diff) - Math.abs(b.diff)
            });
        }
        return {
            filterIns: '',
            columns
        }
    },
    computed: {
        strategyIds() {
            if (this.orders) {
                return _.chain(this.orders)
                    .toPairs()
                    .map(([key,val]) => {
                        let diffs = _.sumBy(val, o=> {
                            if (o.target) {
                                return Math.abs(o.target-o.position)
                            }
                            return 0;
                        });
                        return [key, diffs];
                    })
                    .value()
                    .sort(function (a, b) {
                        if (a[1] != b[1]) {
                            return b[1] - a[1];
                        }
                        if (a[0].length != b[0].length) {
                            return a[0].length - b[0].length;
                        }
                        if (a[0] > b[0]) {
                            return 1;
                        }
                        if (b[0] > a[0]) {
                            return -1;
                        }
                        return 0;
                    });
            }
            return [];
        },
        filteredOrders() {
            return _.chain(this.orders)
            .toPairs()
            .map(([key, val]) => {
                let values = _.chain(val)
                    .filter(i => i.instrument.toUpperCase().includes(this.filterIns.toUpperCase()))
                    .map(i => {
                        return {
                            ...i,
                            diff: i.target - i.position
                        };
                    })
                    .sortBy('diff')
                    .reverse()
                    .value();
                
                return [key, values];
            })
            .fromPairs()
            .value();
        }
    },
    components: {
        OrderPosition
    }
}
</script>

<style lang="less" scoped>
.container {
    overflow: auto;
    // height: 100vh;
}
</style>