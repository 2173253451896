<template lang="pug">
div 
    a-form(:label-col="{ span: 6 }" :wrapper-col="{ span: 18 }")
        a-form-item(label="合约")
            span {{ trading_code }}
        a-form-item(label="Delta")
            span {{ current.delta }}
        a-form-item(label="Option Price")
            span {{ current.last }}
        a-form-item(label="Spot Price")
            span {{ spotPrice }}
        a-form-item(label="手数")
            a-input-number(v-model="num" @change="handleNum" style="width:100%")
        a-form-item(label="名义市值")
            a-input-number(v-model="marketVal" @change="handleMkVal" style="width:100%")
</template>

<script>
export default {
    props: ['trading_code', 'spotPrice', "current"],
    data() {
        return {
            num: 0,
            marketVal: 0,
        }
    },
    methods: {
        handleMkVal(val) {
            console.log('handle mk val')
            let contract_multiplier = 100;
            this.num = Math.round(val/(this.current.delta*contract_multiplier*this.spotPrice)*100)/100;
        },
        handleNum(val) {
            console.log('handleNum')
            let contract_multiplier = 100;
            this.marketVal = Math.round(val*this.current.delta*this.spotPrice*contract_multiplier*100)/100;
        }
    }
}
</script>