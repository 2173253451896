<template lang="pug">
.page 
    a-tabs(type="card" @change="handleChange")
        a-tab-pane(key="22102603", tab="22102603")
            OptionView(spotName="000852.SH" etfCode="512100.SH" :isActive="currentKey=='22102603'" :name="''")
        a-tab-pane(key="22200525", tab="22200525")
            OptionView(spotName="000852.SH" etfCode="512100.SH" :isActive="currentKey=='22200525'" name="22200525")
    //- a-card(title="000852.SH")
    //-     OptionView(spotName="000852.SH" etfCode="512100.SH")
</template>

<script>
import OptionView from './OptionView.vue';

export default {
    data() {
        return {
            currentKey: '22102603',
        }
    },
    methods: {
        handleChange(key) {
            this.currentKey = key;
        }
    },
    components: {
        OptionView,
    }
}
</script>