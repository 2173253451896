import Vue from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import moment from 'moment'
import VueMq from 'vue-mq'

import './main.less'
import router from './router'
import store from './store'
import api from './plugins/api'
import io from './plugins/io'
import perms from './plugins/perms'

import CommonTable from './components/commonTable'

moment.locale('zh-cn');

Vue.config.productionTip = false

Vue.use(Antd);
Vue.use(api);
Vue.use(io);
Vue.use(perms);
Vue.use(VueMq, {
    breakpoints: {
      sm: 450,
      md: 1250,
      lg: Infinity,
    }
});
Vue.component('z-table', CommonTable);

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
