<template lang="pug">
.page 
    a-modal(:visible="isAddVisible"
        @cancel="isAddVisible=false"
        @ok="submitAdd"
        title="添加账号")
        a-form 
            a-form-item(label="账号名称")
                a-input(v-model="newData.name")
            a-form-item(label="地址")
                a-input(v-model="newData.address")
            a-form-item(label="描述")
                a-input(v-model="newData.desc")
            a-form-item(label="仅白天交易")
                a-switch(v-model="newData.onlyDay")

    .ctl-bar
        a-button(type="primary" 
            @click="handleAdd")
            a-icon(type="plus")
            span 添加账号
        a-input-search(placeholder="search"
            v-model="filterValue"
            style="margin-left:10px;width:200px")
    a-table(:dataSource="filterAccounts"
        :columns="columns"
        size="small"
        :pagination="{pageSize: 15}"
        rowKey="_id")
        template(slot="operator" slot-scope="record")
            //- a(@click="handleEdit(record)")
            //-     a-icon(type="edit")
            //- a-divider(type="vertical")
            a-popconfirm(title="确认删除？" @confirm="submitDel(record._id)")
                a 
                    a-icon(type="delete")
        account-link(slot="name" 
            :name="record.name"
            :id="record._id"
            slot-scope="name, record")

</template>

<script>
import AccountLink from '../links/Account.vue'

export default {
    data() {
        return {
            newData: {
                name: '',
                address: '',
                desc: '',
                onlyDay: false,
            },
            filterValue: '',
            isAddVisible: false,
            columns: [
                {
                    title: '账号名称',
                    dataIndex: 'name',
                    scopedSlots: {
                        customRender: 'name',
                    }
                },
                {
                    title: '地址',
                    dataIndex: 'address',
                },
                {
                    title: '描述',
                    dataIndex: 'desc',
                },
                {
                    title: '操作',
                    scopedSlots: {
                        customRender: 'operator',
                    }
                }
            ],
            accounts: []
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        handleAdd() {
            this.isAddVisible = true;
            this.newData = {
                ...this.newData,
                name: '',
            };
        },
        submitAdd() {
            this.$api.request("POST", 'account', this.newData)
                .then(() => {
                    this.getData();
                    this.isAddVisible = false;
                })
        },
        submitDel(id) {
            this.$api.request('DELETE', 'account/'+id)
            .then(() => {
                this.getData();
            });
        },
        getData() {
            this.$api.request('Get', 'accounts')
            .then(res => {
                this.accounts = res.data;
            })
        }
    },
    computed: {
        filterAccounts() {
            return this.accounts.filter(a => {
                if (!this.filterValue) return true;
                let f = this.filterValue.trim().toUpperCase();
                return a.name.toUpperCase().includes(f) || a.address.toUpperCase().includes(f) || a.desc.toUpperCase().includes(f);
            });
        }
    },
    components: {
        AccountLink
    }
}
</script>

<style lang="less" scoped>

</style>