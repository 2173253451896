<template lang="pug">
.page 
    z-table(:columns="columns" route="strategy")
</template>

<script>
export default {
    data() {
        return {
            columns: [
                {
                    title: '名称',
                    dataIndex: 'account',
                    addAble: true,
                    editAble: true,
                    searchAble: true,
                },
                {
                    title: '地址',
                    dataIndex: 'address',
                    addAble: true,
                    editAble: true,
                    searchAble: true,
                    scopedSlots: { customRender: 'accountAddress' },
                },
                {
                    title: '资金量',
                    dataIndex: 'asset',
                    addAble: true,
                    editAble: true,
                    type: 'Number',
                    min: 0,
                },
                {
                    title: '客户端ID',
                    dataIndex: 'clientId',
                    addAble: true,
                    editAble: true,
                    searchAble: true,
                },
                {
                    title: '策略组',
                    dataIndex: 'strategy',
                    addAble: true,
                    editAble: true,
                    searchAble: true,
                    type: 'Enum',
                    enum: [
                        'strat1_LF'
                    ]
                },
                {
                    title: '合约类型',
                    dataIndex: 'InstType',
                    addAble: true,
                    editAble: true,
                    searchAble: true,
                    type: 'Enum',
                    enum: [
                        'cbond',
                        'stock',
                        'cta'
                    ]
                },
                {
                    title: '添加时间',
                    dataIndex: 'createdAt',
                    addAble: false,
                },
                
            ] 
        }
    }
}
</script>