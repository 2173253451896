<template lang="pug">
.page
    a-drawer(:visible="sendVisible"
        :destroyOnClose="true"
        :closable="false"
        :zIndex="1001"
        title="下单"
        width="80%")
        OrderSendPage
        
    a-modal(v-model="addVisible"
        @close="addVisible=false"
        @ok="submitAdd"
        title="创建策略")
        a-form(:label-col="labelCol"
            :wrapper-col="wrapperCol")
            a-form-item(label="策略名称")
                a-input(v-model="addContent.name")
            a-form-item(label="交易账号")
                a-select(v-model="addContent.tradeAccount" 
                    style="width: 100%"
                    placeholder="交易账号")
                    a-select-option(v-for="item in tradeAccounts"
                    :key="item._id" :value="item._id") {{item.account}} [{{item.address}}]
            a-form-item(label="描述")
                a-input(v-model="addContent.desc")
            a-form-item(label="Level")
                a-select(v-model="addContent.level")
                    a-select-option(value="test") test 
                    a-select-option(value="demo") demo
            a-form-item(label="白名单")
                a-textarea(v-model="addContent.whiteIps" 
                    :rows="2"
                    placeholder="eg: 192.168.1.39;192.168.1.21")
    a-modal(v-model="upVisible"
        @close="upVisible=false"
        @ok="submitUpdate"
        title="编辑策略")
        a-form(:label-col="labelCol"
            :wrapper-col="wrapperCol")
            a-form-item(label="策略名称")
                a-input(v-model="upContent.name")
            a-form-item(label="描述")
                a-input(v-model="upContent.desc")
            a-form-item(label="Weight")
                a-input-number(v-model="upContent.weight" :style="{width: '100%'}")
            a-form-item(label="Level")
                a-select(v-model="upContent.level")
                    a-select-option(value="test") test 
                    a-select-option(value="demo") demo
            a-form-item(label="白名单")
                a-textarea(v-model="upContent.whiteIps" 
                    :rows="2"
                    placeholder="eg: 192.168.1.39;192.168.1.21")
    .ctl-bar 
        a-button(type="primary" @click="handleCreate") 创建
        a-input-search(placeholder="search"
            v-model="filterValue"
            @change="onSearch"
            style="margin-left:10px;width:200px")
        a-upload(name="orders" 
            style="margin-left:10px"
            :showUploadList="false"
            :beforeUpload="beforeUpload"
            :directory="true"
            :multiple="false")
            a-button(type="primary")
                a-icon(type="upload") 
                span 打开持仓文件夹(pos_*)
    a-table(:columns="columns" 
        :rowKey="(record) => record._id"
        :loading="loading"
        :pagination="{total, current, onChange:handlePageChange, pageSize:15}"
        size="small", 
        :dataSource="items")
        template(slot="freeze" slot-scope="record")
            a-popconfirm(title="确认切换？" @confirm="submitFreeze(record)")
                a-switch(v-model="!record.isFreeze" )
        template(slot="strategy" slot-scope="item, record")
            StrategyItem(:name="item" :id="record._id" :weight="record.weight")
        template(slot="tradeAccount" slot-scope="item, record")
            TradeAccountItem(:id="item._id" :address="item.address" :account="item.account")
            //- span {{item ? item.account : "empty"}}
            //- span(v-if="item" style="color:#8500ff;margin-left:5px") {{item.address}}
        template(slot="operate" slot-scope="record")
            a-popconfirm(title="确认删除？" @confirm="submitDel(record._id)")
                a 
                    a-icon(type="delete")
            a-divider(type="vertical")
            a(@click="handleUpdate(record)") 
                a-icon(type="edit")
</template>

<script>
import moment from 'moment';
import OrderHistory from './OrderHistory.vue';
import StrategyItem from '../../links/StrategyItem.vue';
import TradeAccountItem from '../../links/TradeAccountItem.vue';
import OrderSendPage from './OrderSendPage.vue';
import {mapState} from 'vuex';
import _ from 'lodash';

export default {
    data() {
        return {
            loading: false,
            columns: [
                {
                    title: '策略名称',
                    dataIndex: 'name',
                    scopedSlots: {
                        customRender: 'strategy'
                    }
                },
                {
                    title: '交易账号',
                    dataIndex: 'tradeAccount',
                    scopedSlots: {
                        customRender: 'tradeAccount'
                    }
                },
                {
                    title: 'Weight',
                    dataIndex: 'weight',
                },
                {
                    title: '描述',
                    dataIndex: 'desc',
                },
                {
                    title: '创建时间',
                    dataIndex: 'createdAt',
                },
                {
                    title: '更新时间',
                    dataIndex: 'updatedAt',
                },
                {
                    title: '交易启停',
                    scopedSlots: {
                        customRender: 'freeze'
                    }
                },
                {
                    title: '操作',
                    scopedSlots: {
                        customRender: 'operate'
                    }
                }
            ],
            items: [],
            total: 0,
            current: 1,
            addVisible: false,
            addContent: {
                name: '',
                tradeAccount: '',
                level: 'demo',
                desc: '', 
                whiteIps: '',
            },
            upVisible: false,
            upContent: {
                id: '',
                name: '',
                level: '',
                tradeAccount: '',
                desc: '', 
                whiteIps: '',
                weight: 1,
            },
            filterValue: '',
            tradeAccounts: [],
            strategys: [],
        };
    },
    mounted() {
        this.getTradeAccounts();
        this.getList();
        this.getNames();
    },
    methods: {
        onSearch() {
            this.current = 1;
            this.getList();
        },
        handlePageChange(page) {
            this.current = page;
            this.getList();
        },
        handleUpdate(record) {
            this.upVisible = true;

            this.upContent = {
                id: record._id,
                name: record.name,
                desc: record.desc,
                level: record.level,
                weight: record.weight,
                whiteIps: record.whiteIpList.join(';'),
                tradeAccount: record.tradeAccount._id,
            }
        },
        handleCreate() {
            this.addVisible = true;
        },
        async beforeUpload(file) {
            // if(!(/pos_[0-9]+\.txt$/.test(file.name))) return false;
            let strategyName = file.name.replace(/\.txt$/, '');
            let t = _.find(this.strategys, i => i.name == strategyName);
            if (!t) return false;

            let fr = new FileReader();
            let p = new Promise((resolve, reject) => {
                fr.onload = () => {
                    resolve(fr.result);
                };
                fr.onerror = reject;
            });
            
            fr.readAsText(file);

            let config = await p;
            let positions = {};

            config.trim().split('\n')
                .map(i => {
                    let j = i.trim().split(/[\t|\s|,]+/);
                    let instrument = j[0].trim();
                    let target = parseInt(j[1].trim());

                    positions[instrument] = target;
                });
            this.$store.commit('order/addTargetPosition', {
                name: t.name,
                weight: t.weight,
                _id: t._id,
                positions
            });
            return false;
        },
        submitFreeze(record) {
            console.log(record);
            let url = record.isFreeze ? 'strategy/unFreeze/' : 'strategy/freeze/';
            this.$api.request('POST', url+record._id, {}, 'order')
                .then(() => {
                    record.isFreeze = !record.isFreeze;
                    this.getList();
                });
        },
        submitDel(id) {
            this.$api.request('DELETE', 'strategy/'+id, {}, 'order')
                .then(() => {
                    this.getList();
                    this.getNames();
                })
                .catch((r) => {
                    console.log(r.response.data);
                    this.$message.error("删除策略失败");
                });
        },
        submitUpdate() {
            this.$api.request('POST', 'strategy/update/'+this.upContent.id, this.upContent, 'order')
                .then(() => {
                    this.getList();
                    this.getNames();
                    this.upVisible = false;
                })
                .catch(() => {
                    this.$message.error("更新策略失败");
                });
        },
        submitAdd() {
            this.$api.request('POST', 'strategy/create', this.addContent, 'order')
                .then(() => {
                    this.getList();
                    this.getNames();
                    this.addVisible = false;
                })
                .catch(() => {
                    this.$message.error("增加策略失败");
                });
        },
        getList() {
            if (this.loading == true) return;
            this.loading = true;
            this.$api.request('GET', 'strategy/list', {
                filterValue: this.filterValue.trim(),
                page: this.current-1,
                size: 15
            }, 'order')
                .then(res => {
                    let {total, data} = res.data;
                    this.items = data.map(d => ({
                        ...d,
                        createdAt: moment(d.createdAt).format('lll'),
                        updatedAt: moment(d.updatedAt).format('lll'),
                    }));
                    this.total = total;
                    this.loading = false;
                })
        },
        getNames() {
            this.$api.request('GET', 'strategy/names', {}, 'order')
                .then(res => {
                    this.strategys = res.data;
                })
        },
        getTradeAccounts() {
            this.$api.request('GET', 'tradeAccount/list', {}, 'order')
                .then(res => {
                    this.tradeAccounts = res.data;
                })
        },
    },
    computed: {
        ...mapState('order', ['sendVisible'])
    },
    components: {
        OrderHistory,
        StrategyItem,
        TradeAccountItem,
        OrderSendPage,
    }
}
</script>