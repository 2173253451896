<template lang="pug">
.page 
    a-tabs(@tabClick="refresh")
        a-tab-pane(tab="策略管理" key="strategy")
            Strategy
        a-tab-pane(tab="账户管理" key="tradeAccount")
            TradeAccount
        //- a-tab-pane(tab="综合下单界面" key="orderOperate")
        //-     OrderOperate
        //- a-tab-pane(tab="策略管理" key="current")
</template>

<script>
import TradeAccount from '../components/order/TradeAccount.vue';
import Strategy from '../components/order/Strategy.vue';

import OrderOperate from '../components/order/OrderOperate.vue';

export default {
    methods: {
        
        refresh(tab) {
            if (tab == 'strategy') {
                console.log(tab)
            }
            else if (tab == 'tradeAccount') {
                console.log(tab)
            }
           
        },
    },
    components: {
        TradeAccount,
        Strategy,
        OrderOperate
    }
}

</script>