<template lang="pug">
div 
    a-drawer(:visible="visible"
        width="600px"
        :title="`${strategyId}:  ${instrumentId}`"
        :destroyOnClose="true"
        @close="visible=false")
        a-list(:dataSource="items")
            a-list-item(slot="renderItem"
                :key="item._id"
                slot-scope="item,index")
                a-list-item-meta(:description="item.createdAt")
                    template(slot="title")
                        a-row(slot="title") 
                            a-col(:span="8") Order ID: {{item.orderId}}
                            a-col(:span="8") 实际交易: {{item.realTraded}}
                            a-col(:span="8") 目标持仓: {{item.targetPosition}}
                        div(v-if="item.orderStatus && item.orderStatus.traded")
                            a-row(v-for="i,ix in item.orderStatus.traded"
                                :key="ix")
                                a-col(:span="6") trade ID: {{i.tradeId.trim()}}
                                a-col(:span="6") volume: {{i.volume}}
                                a-col(:span="6") price: {{parseInt(i.price*1000)/1000}}
                                a-col(:span="6") tradeTime: {{i.tradeTime}}
    a(@click="getData") {{instrumentId}}
</template>

<script>
import moment from 'moment';

export default {
    props: ['instrumentId', 'strategyId'],
    data() {
        return {
            visible: false,
            items: []
        }
    },
    methods: {
        getData() {
            this.visible = true;
            this.$api.request('GET', 'onlineorder/position/'+this.strategyId+'/'+this.instrumentId)
            .then(res => {
                this.items = res.data.map(i => ({
                    ...i,
                    createdAt: moment(i.createdAt).format('lll')
                }));
            });
        }
    }
}
</script>
