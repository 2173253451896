const state = {
    title: '',
    showMenu: false,
};

const getters = {

};

const mutations = {
    setTitle(state, val) {
        state.title = val;
    },
    setVisible(state, val) {
        state.showMenu = val;
    }
};

const actions = {

};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}