<template lang="pug">
.page
    .ctl-bar
        a-button(type="primary" style="margin-right:20px" @click="getData")
            a-icon(type="redo")
            span 刷新
        a-upload(:multiple="true" 
            :showUploadList="false"
            style="margin-right:20px" 
            :beforeUpload="beforeUpload")
            a-button(type="primary")
                a-icon(type="upload")
                span 上传

        a-dropdown(trigger="click" v-if="selected")
            a-button(type="primary") Move To

            a-menu(slot="overlay")
                a-menu-item(v-for="sub in subGroups"
                    @click="moveTo(sub)"
                    :key="sub") 
                    a-icon(type="folder")
                    span {{ sub }}

    a-table(:columns="columns" 
        size="small" 
        :dataSource="items" 
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        rowKey="_id")
        

        template(slot="operate" slot-scope="record")
            a-popconfirm(title="确认删除？" @confirm="submitDel(record._id)")
                a
                    a-icon(type="delete" class="operate-icon")
            a-divider(type="vertical")
            a(@click="handleDownload(record._id, record.name)")
                a-icon(type="download")
</template>

<script>
import axios from 'axios';
import moment from 'moment/moment';

const host = '/api/fs';

export default {
    props: ['group', 'subGroup', 'subGroups'],
    data() {
        return {
            selectedRowKeys: [],
            columns: [
                {
                    title: '文件名',
                    dataIndex: 'name',
                },
                {
                    title: '大小',
                    dataIndex: 'size',
                },
                {
                    title: 'Tags',
                    dataIndex: 'tags',
                },
                {
                    title: '创建时间',
                    dataIndex: 'createdAt',
                },
                {
                    title: '操作',
                    scopedSlots: { customRender: 'operate' },
                }
            ],
            items: []
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        moveTo(subGroup) {
            this.$api.request('POST', 'subGroup/move/' + this.group, {subGroup, files: this.selectedRowKeys}, 'fs')
                .then(res => {
                    this.getData();
                });
        },
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys;
        },
        async beforeUpload(file, fileList) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('group', this.group);
            formData.append('subgroup', this.subGroup);

            try {
                await axios.post(`${host}/upload`, formData);
                this.$message.success('upload success');
            } catch (error) {
                this.$message.error('upload fail');
            }

            this.getData();
            
            return false;
        },
        submitDel(id) {
            axios.delete(`${host}/file/${id}`).then(res => {
                this.getData();
            });
        },
        handleDownload(id, name) {
            axios.get(`${host}/download/${id}`).then(res => {
                console.log
                let url = window.URL.createObjectURL(new Blob([res.data]));
                let docUrl = document.createElement('a');
                docUrl.href = url;
                docUrl.setAttribute('download', name);
                document.body.appendChild(docUrl);
                docUrl.click();
                window.URL.revokeObjectURL(url);
                docUrl.remove();
            });
        },
        getData() {
            this.$api.request('GET', `files/${this.group}/${this.subGroup}`, {}, 'fs')
            .then(res => {
                this.items = res.data.map(item => {
                    let size = item.size;
                    if (item.size > 1024*1024*1024) {
                        size = `${Math.round(item.size*10/(1024*1024*1024))/10} G`
                    }
                    else if (item.size > 1024*1024) {
                        size = `${Math.round(item.size*10/(1024*1024))/10} M`
                    }
                    else if (item.size > 1024) {
                        size = `${Math.round(item.size*10/(1024))/10} K`
                    }

                    return {
                        ...item,
                        size,
                        createdAt: moment(item.createdAt).format('lll') 
                    }
                });
            });
        }
    },
    computed: {
        selected() {
            return this.selectedRowKeys.length > 0;
        }
    }

}
</script>