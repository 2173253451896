<template lang="pug">
    .page 
        div
            span 交易日选择：
            a-date-picker(@change="onDateChange")
        SpreadChart(code0="cu2109" code1="cu2108" :day="day")
        SpreadChart(code0="al2109" code1="al2108" :day="day")
        SpreadChart(code0="pb2107" code1="pb2108" :day="day")
        SpreadChart(code0="zn2109" code1="zn2108" :day="day")
</template>

<script>
import SpreadChart from '../components/SpreadChart.vue';

export default {
    data() {
        return {
            day: '',
        };
    },
    methods: {
        onDateChange(date){
            if (date) {
                let dateStr = date.format('YYYYMMDD');
                this.day = dateStr;
            }
            
        }
    },
    components: {
        SpreadChart
    }
}
</script>