<template lang="pug">
.page 
    a-row(type="flex")
        a-col(:span="6" 
            v-for="i in $perms" 
            v-if="me&& me.perms.includes(i.value) && !i.invisiable"
            :key="i.value")
            a-card.card-item( 
                @click="goto(i.value, i.label)")
                my-icon(v-if="i.iconCustom" :type="i.iconType")
                a-icon(v-if="!i.iconCustom" :type="i.iconType")
                span {{ i.label }}
</template>

<script>
import { mapState } from 'vuex';
import MyIcon from '../components/MyIcon';

export default {
    methods: {
        goto(key, title) {
            this.$router.replace(`/main/${key}`);
            // this.$store.commit('nav/setVisible', false);
        },
    },
    computed: {
        ...mapState('user', ['me']),
    },
    components: {
        MyIcon,
    },
}
</script>

<style lang="less" scoped>
.card-item {
    cursor: pointer;
}
</style>