<template lang="pug">
div 
    a-drawer(:visible="visible" 
        @close="visible=false"
        :destroyOnClose="true"
        :width="500"
        :title="address")
        a-row.account-row 
            a-col(:span="6") Account:
            a-col(:span="18") {{ info.account }}

        
        a-row.account-row  
            a-col(:span="6") ProductName:
            a-col(:span="18") {{ info.productName }}

        a-row.account-row  
            a-col(:span="6") Url:
            a-col(:span="18") {{ info.url }}

        a-row.account-row  
            a-col(:span="6") Broker:
            a-col(:span="18") {{ info.broker }}

        a-row.account-row  
            a-col(:span="6") Desc:
            a-col(:span="18") {{ info.desc }}
        

    a(@click="handleClick") 
        a-icon(type="account-book")
        //- my-icon(type="icon-account-book" style="margin-right:5px")
        span {{address}}
</template>

<script>
import axios from 'axios';

export default {
    props: ['address'],
    data() {
        return {
            visible: false,
            info: {},
        }
    },
    methods: {
        handleClick() {
            this.visible = true;
            this.getData();
        },
        getData() {
            this.$api.request('GET', 'cbondAccount?url='+this.address)
                .then(res => {
                    this.info = res.data || {};
                });
        }
    }
}
</script>

<style scoped>
.account-row  {
    padding: 5px;
    font-weight: bold;
}
</style>