import Vue from 'vue';
import VueRouter from 'vue-router';

import LoginPage from './pages/Login';
import MainPage from './pages/Main';
import WelecomePage from './pages/Welecome';
import WorkerPage from './pages/Worker';
import OptionParamPage from './pages/OptionParam';
import SpreadChartPage from './pages/Spread';
import StockFactorsPage from './pages/StockFactors/Page.vue';
// import StockFactorsTestPage from './pages/StockTestFactors';

import OnlineOrderPage from './pages/onlineOrder';
import AdminPage from './pages/Admin';
import CbondT0Page from './pages/CbondT0';
import AccountInfoPage from './pages/AccountInfo';
import OrderPage from './pages/Order';
import CbondAccountPage from './pages/CbondAccount';
import NoPermPage from './pages/NoPerm';
import Option1Page from './pages/Option1/Page.vue';
import RiskInstrumentPage from './pages/RiskInstrument';
import ClusterPage from './pages/Cluster';
import GlobalRiskPage from './pages/GlobalRisk';
import StrategyPage from './pages/Strategy.vue';
import DashboardPage from './pages/Dashboard';
import PnlMonitorPage from './pages/PnlMonitor.vue';
import NewsPage from './pages/News';
import LimitUpOrderPage from './pages/LimitUpOrder.vue';
import HKOption from './pages/HKOption/Page.vue';
import Folders from './pages/Folders.vue';
import store from './store';
import CtaAccount from './pages/CtaAccount.vue';
import FileAdmin from './pages/FileAdmin.vue';
import ToolsApiPage from './components/ToolsApiPage.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        // component: WelecomePage,
        redirect: '/main',
    },
    {
        path: '/login',
        component: LoginPage,
    },
    {
        path: '/main',
        component: MainPage,
        children: [
            {
                path: '/',
                component: WelecomePage,
            },
            {
                path: 'noperm',
                component: NoPermPage,
            },
            {
                path: 'dashboard',
                component: DashboardPage,
            },
            {
                path: 'worker',
                perm: 'worker',
                component: WorkerPage,
            },
            {
                path: 'optionParam',
                perm: 'optionParam',
                component: OptionParamPage,
                meta: {
                    keepAlive: true,
                },
            },
            {
                path: 'spreadChart',
                perm: 'spreadChart',
                component: SpreadChartPage,
            },
            {
                path: 'stockFactors',
                perm: 'taskReport',
                component: StockFactorsPage,
            },
            // {
            //     path: 'stockFactorsTest',
            //     perm: 'taskReport',
            //     component: StockFactorsTestPage,
            // },
            {
                path: 'cluster',
                perm: 'cluster',
                component: ClusterPage,
            },
            // {
            //     path: 'taskReport',
            //     perm: 'taskReport',
            //     component: TaskReportPage
            // },
            {
                path: 'onlineOrder',
                perm: 'onlineOrder',
                // component: OnlineOrderPage
                component: OrderPage,
            },
            {
                path: 'limitUpOrder',
                perm: 'limitUpOrder',
                component: LimitUpOrderPage,
            },
            {
                path: 'hkOption',
                perm: 'hkOption',
                component: HKOption,
            },
            {
                path: 'hkOptionRead',
                perm: 'hkOptionRead',
                component: HKOption,
            },
            {
                path: 'order',
                perm: 'order',
                component: OrderPage,
            },
            {
                path: 'strat_Option1',
                perm: 'strat_Option1',
                component: Option1Page,
            },
            {
                path: 'strat_cbondT0',
                perm: 'strat_cbondT0',
                component: CbondT0Page,
            },
            {
                path: 'accountInfo',
                perm: 'accountInfo',
                component: AccountInfoPage,
            },
            {
                path: 'cbondAccount',
                perm: 'cbondAccount',
                component: CbondAccountPage,
            },
            {
                path: 'ctaAccount',
                perm: 'ctaAccount',
                component: CtaAccount,
            },
            {
                path: 'riskInstrument',
                perm: 'riskInstrument',
                component: RiskInstrumentPage,
            },
            {
                path: 'globalRisk',
                perm: 'globalRisk',
                component: GlobalRiskPage,
            },
            {
                path: 'strategy',
                perm: 'strategy',
                component: StrategyPage,
            },
            {
                path: 'folders',
                component: Folders,
            },
            {
                path: 'news',
                perm: 'news',
                component: NewsPage,
            },
            {
                path: 'pnlMonitor',
                perm: 'pnlMonitor',
                component: PnlMonitorPage,
            },

            {
                path: 'admin',
                perm: 'admin',
                component: AdminPage,
            },
            {
                path: 'adminFile',
                perm: 'adminFile',
                component: FileAdmin,
            },
            {
                path: 'toolsDoc',
                perm: 'toolsDoc',
                component: ToolsApiPage,
            }
        ],
    },
];

const router = new VueRouter({ routes });
router.beforeEach((to, from, next) => {
    let perm = to.perm;
    let me = store.state.user.me;

    if (me && perm) {
        if (!me.perms.includes(perm)) return next('/main/noperm');
    }
    next();
});

export default router;
