<template lang="pug">
.page
    a-card(title="持仓文件下单")
        a-upload(name="orders" 
            accept=".json"
            :showUploadList="false"
            :beforeUpload="beforeUpload"
            :directory="true"
            :multiple="false")
            a-button(type="primary")
                a-icon(type="upload") 
                span 打开配置文件夹
</template>

<script>

export default {
    data() {
        return {

        }
    },
    methods: {
        async beforeUpload(file) {
            if(!(/\.txt$/.test(file.name))) return false;
            let fr = new FileReader();

            let p = new Promise((resolve, reject) => {
                fr.onload = () => {
                    resolve(fr.result);
                };
                fr.onerror = reject;
            });
            
            fr.readAsText(file);

            let config = await p;
            let c = {};
            let strategyId = file.name.replace(/\.txt$/, '');
            if (this.currentStrategyIds.includes(strategyId)) {
                c[strategyId] = config;
            }
            
           console.log(config); 
            // this.config = {
            //     ...this.config,
            //     ...c
            // }
            // this.sendVisible = true;
        }
    }
}
</script>