<template lang="pug">
.page 
    //- a-input-search(placeholder="输入地址" 
    //-     @search="handleConnect" 
    //-     size="large"
    //-     style="max-width:400px"
    //-     enter-button="连接")
    a-tabs(v-model="activeKey" )
        
        //- a-tab-pane(key="6101" tab="505000011128")
        //-     PanelMerccbond(v-if="mode=='cbond'" 
        //-         account="505000011128"
        //-         download="6001.csv"
        //-         port="6101")
        //- a-tab-pane(key="6008" tab="test(t)")
        //-     PanelMerccbond(v-if="mode=='cbond'" 
        //-         account="test"
        //-         download="6002.csv"
        //-         port="6008")
        //- a-tab-pane(key="6104" tab="18077897(gj_2)")
        //-     PanelMerccbond(v-if="mode=='cbond'" 
        //-         account="18077897"
        //-         download="6004.csv"
        //-         :activeKey="activeKey"
        //-         port="6104")
        //- a-tab-pane(key="6105" tab="18079369(gj_5)" v-if="me&& me.perms.includes('onlineOrderPlus')")
        //-     PanelMerccbond(v-if="mode=='cbond'" 
        //-         account="18079369"
        //-         download="6005.csv"
        //-         :activeKey="activeKey"
        //-         port="6105")
        //- a-tab-pane(key="6102" tab="398000105805(2)" v-if="me&& me.perms.includes('onlineOrderPlus')")
        //-     PanelMerccbond(v-if="mode=='cbond'" 
        //-         account="398000105805"
        //-         download="6002.csv"
        //-         :activeKey="activeKey"
        //-         port="6102")
        //- a-tab-pane(key="6101" tab="398000105876(3)")
        //-     PanelMerccbond(v-if="mode=='cbond'" 
        //-         account="398000105876"
        //-         download="6001.csv"
        //-         :activeKey="activeKey"
        //-         port="6101")
        //- a-tab-pane(key="6103" tab="398000105023(13)" v-if="me&& me.perms.includes('onlineOrderPlus')")
        //-     PanelMerccbond(v-if="mode=='cbond'" 
        //-         account="398000105023"
        //-         download="6003.csv"
        //-         :activeKey="activeKey"
        //-         port="6103")
        //- a-tab-pane(key="6104" tab="398000105210(15)")
        //-     PanelMerccbond(v-if="mode=='cbond'" 
        //-         account="398000105210"
        //-         download="6004.csv"
        //-         port="6104")
        //- a-tab-pane(key="6100" tab="398000104947(25)" v-if="me&& me.perms.includes('onlineOrderPlus')")
        //-     PanelMerccbond(v-if="mode=='cbond'" 
        //-         account="398000104947"
        //-         download="6000.csv"
        //-         :activeKey="activeKey"
        //-         port="6100")
        //- a-tab-pane(key="6107" tab="398000105456(31)")
        //-     PanelMerccbond(v-if="mode=='cbond'" 
        //-         account="398000105456"
        //-         download="6007.csv"
        //-         :activeKey="activeKey"
        //-         port="6107")
        a-tab-pane(key="7778" tab="模拟")
            PanelMerccbond(v-if="mode=='cbond'" 
                account="模拟1"
                download="6000.csv"
                :activeKey="activeKey"
                num="74"
                port="7778")

        a-tab-pane(key="7777" tab="模拟2")
            PanelMerccbond(v-if="mode=='cbond'" 
                account="模拟2"
                download="6000.csv"
                :activeKey="activeKey"
                num="74"
                port="7777")
        
        a-tab-pane(key="7003" tab="20132818(gj_product1)"
            v-if="me&& me.perms.includes('strat_cbondT0_all')")
            PanelMerccbond(v-if="mode=='cbond'" 
                account="20132818"
                download="7003.csv"
                :activeKey="activeKey"
                num="38"
                port="7003")
        
        a-tab-pane(key="7009" tab="11020009199892(product8_hedge)"
            v-if="me&& me.perms.includes('strat_cbondT0_all')")
            PanelMerccbond(v-if="mode=='cbond'" 
                account="20132818"
                download="7009.csv"
                :activeKey="activeKey"
                num="38"
                port="7009")
        
        //- a-tab-pane(key="6104" tab="398000105210(15)")
        //-     PanelMerccbond(v-if="mode=='cbond'" 
        //-         account="398000105210"
        //-         download="6004.csv"
        //-         port="6104")
        //- a-tab-pane(key="6105" tab="398000105209(18)")
        //-     PanelMerccbond(v-if="mode=='cbond'" 
        //-         account="398000105209"
        //-         download="6005.csv"
        //-         port="6105")
        //- a-tab-pane(key="6106" tab="398000105008(30)")
        //-     PanelMerccbond(v-if="mode=='cbond'" 
        //-         account="398000105008"
        //-         download="6006.csv"
        //-         port="6106")
        //- a-tab-pane(key="6107" tab="398000105456(31)")
        //-     PanelMerccbond(v-if="mode=='cbond'" 
        //-         account="398000105456"
        //-         download="6007.csv"
        //-         port="6107")

    
</template>

<script>
import {mapState} from 'vuex';
// import axios from 'axios';
import PanelMerccbond from '../components/param-adjust-panels/PanelMerccbond.vue';

export default {
    data() {
        return {
            activeKey: '6100',
            targetAddress: '',
            mode: 'cbond',
            visible: false,
            
            detailVisible: false
        }
    },
    mounted() {

    },
    methods: {
        handleConnect(address) {
            this.targetAddress = address;
        }
        
    },
    computed: {
        ...mapState('user', ['me'])
    },
    components: {
        PanelMerccbond
    }
}
</script>

<style lang="less" scoped>
.control-items {
    margin-bottom: 10px;
}
</style>

