<template lang="pug">
a-tabs 
    a-tab-pane(tab="PNL" key="pnl")
        chart(:option="option" style="max-height: 400px;height:70vh")
    a-tab-pane(tab="账号持仓（参考）" key="posiiton")
        a-table(:dataSource="positions"
            :columns="posColumns"
            size="small"
            :pagination="{pageSize:25}"
            rowKey="instrumentID")
</template>

<script>
import Chart from './Chart.vue'
import _ from 'lodash';

export default {
    props: ["accountId"],
    data() {
        return {
            d: [],
            positions: [],
            posColumns: [
                {
                    title: '品种',
                    dataIndex: 'instrumentID'
                },
                {
                    title: '持仓',
                    dataIndex: 'position'
                }
            ]
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            this.$api.request('GET', 'account/'+this.accountId)
            .then(res => {
                this.d = res.data.last5000;
                let positions = res.data.position.map(i => {
                    return {
                        ...i,
                        position: i.Position*(i.direction == 'long' ? 1 : -1)
                    };
                });

                this.positions = _.chain(positions)
                    .groupBy('instrumentID')
                    .toPairs()
                    .map(([k, v]) => {
                        return {
                            'instrumentID': k,
                            'position': _.sumBy(v, (i) => i.Position*(i.direction == 'long' ? 1 : -1))
                        }
                    })
                    .value();
            });
        }
    },
    computed: {
        option() {
            return {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross'
                    }
                },
                legend: {
                    data: ['动态权益'],
                    // left: 10,
                    // orient: 'vertical'
                },
                xAxis: {
                    type: 'time'
                },
                yAxis: {
                    type: 'value',
                    scale: true,
                },
                series: [
                    {
                        data: this.d.map(i => {
                            return {
                                name: i.createdAt,
                                value: [
                                    i.createdAt,
                                    i.Balance,
                                ]
                            }
                        }),
                        type: 'line',
                        name: '动态权益',
                        showSymbol: false,
                    },
                    // {
                    //     data: this.d.map(i => {
                    //         return {
                    //             name: i.createdAt,
                    //             value: [
                    //                 i.createdAt,
                    //                 i.Available,
                    //             ]
                    //         }
                    //     }),
                    //     type: 'line',
                    //     name: '可用资金',
                    //     showSymbol: false,
                    // }
                ]
            }
        }
    },
    components: {
        Chart
    }    
}
</script>

