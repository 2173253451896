<template lang="pug">
div         
    a-table(:columns="etfColumns" 
        bordered
        :dataSource="[etfPosition]"
        :pagination="{hideOnSinglePage: true}"
        size="small")
        template(slot="title") 
            span ETF当前持仓

        template(slot="currentPrice")
            span {{ etfLastPrice }}

        template(slot="position")
            a-input-number(:value="etfPosition.position" 
                @change="handleEtfPos"
                size="small"
                style="width:150px")

        template(slot="price")
            a-input-number(:value="etfPosition.price" 
                @change="handleEtfPrice"
                size="small"
                style="width:150px")

        template(slot="marketVal")
            span {{ new Intl.NumberFormat('en-CN').format(etfMarketVal) }}

        template(slot="diff")
            span {{ new Intl.NumberFormat('en-CN').format(Math.round((optionMarketVal + etfMarketVal)*100)/100) }}

        template(slot="adjust")
            span {{ Math.round((optionMarketVal + etfMarketVal)/etfLastPrice/100*100)/100 }}
            //- a-button(type="primary" size="small") Add
    br
    a-table(:columns="optionColumns" 
        bordered
        :dataSource="optPositionsDynamic"
        :pagination="{hideOnSinglePage: true}"
        size="small")
        template(slot="currentPrice")
            span {{ etfLastPrice }}


        template(slot="title") 
            span 期权当前持仓
            a-button(size="small" 
                :disabled="!seleted"
                type="primary" 
                @click="addOptPosition") Add

        template(slot="position" slot-scope="d,record")
            a-input-number(style="width:150px" 
                :value="d"
                size="small" 
                @change="handleOptPos(record, $event)")

        template(slot="price" slot-scope="d,record")
            a-input-number(:value="d"
                @change="handleOptPrice(record, $event)"
                size="small"
                style="width:150px")

        template(slot="marketVal" slot-scope="d")
            span {{ new Intl.NumberFormat('en-CN').format(d) }}

        template(slot="del" slot-scope="record")
            a-popconfirm(title="确认删除？" @confirm="delOptPosition(record.code)")
                a
                    a-icon(type="delete" class="operate-icon")
    br
    a-table(:columns="futureColumns"
        bordered
        :dataSource="futurePositionsDynamic"
        :pagination="{hideOnSinglePage: true}"
        size="small")
        template(slot="title") 
            span 期货
            a-input(size="small" style="max-width:150px;margin:0 10px" v-model="currentFuture")
            a-button(size="small" 
                type="primary" 
                @click="addFuturePosition") Add

        template(slot="position" slot-scope="d,record")
            a-input-number(style="width:150px" 
                :value="d"
                size="small" 
                @change="handleFuturePos(record, $event)")

        template(slot="price" slot-scope="d,record")
            a-input-number(:value="d"
                @change="handleFuturePrice(record, $event)"
                size="small"
                style="width:150px")

        template(slot="marketVal" slot-scope="d")
            span {{ new Intl.NumberFormat('en-CN').format(d) }}

        template(slot="del" slot-scope="record")
            a-popconfirm(title="确认删除？" @confirm="delFuturePosition(record.code)")
                a
                    a-icon(type="delete" class="operate-icon")
</template>

<script>
import _ from 'lodash';

export default {
    props: ['etfCode',  'spotCode', 'seleted', 'spotPrice', 'etfPrice', 'dynamicData', 'name'],
    data() {
        let etf = this.dynamicData[this.etfCode];

        return {
            addVisible: false,
            etfPosition: {
                code: this.etfCode,
                position: 0,
                price: etf ? etf.last : 0,
            },
            optPositions: [],
            futurePositions: [],
            etfColumns: [
                {
                    title: '合约',
                    dataIndex: 'code'
                }, 
                {
                    title: '持仓(手)',
                    scopedSlots: {
                        customRender: 'position'
                    }
                },
                {
                    title: '持仓价格',
                    scopedSlots: {
                        customRender: 'price'
                    }
                },
                {
                    title: '当前价格',
                    scopedSlots: {
                        customRender: 'currentPrice'
                    }
                },
                {
                    title: '名义市值',
                    scopedSlots: {
                        customRender: 'marketVal'
                    }
                },
                {
                    title: '差值(元)',
                    scopedSlots: {
                        customRender: 'diff'
                    }
                },
                {
                    title: '补仓(手)',
                    scopedSlots: {
                        customRender: 'adjust'
                    }
                }
            ],
            optionColumns: [
                {
                    title: '合约',
                    dataIndex: 'code',
                }, 
                {
                    title: '持仓(手)',
                    dataIndex: 'position',
                    scopedSlots: {
                        customRender: 'position'
                    }
                },
                {
                    title: '持仓价格(元)',
                    dataIndex: 'price',
                    scopedSlots: {
                        customRender: 'price'
                    }
                },
                {
                    title: '当前价格(元)',
                    dataIndex: 'lastPrice',
                },
                {
                    title: 'Delta',
                    dataIndex: 'delta',
                },
                {
                    title: '名义市值(元)',
                    dataIndex: 'marketVal',
                    scopedSlots: {
                        customRender: 'marketVal'
                    }
                },
                {
                    title: '删除',
                    scopedSlots: {
                        customRender: 'del'
                    }
                },
            ],
            futureColumns: [
                {
                    title: '合约',
                    dataIndex: 'code',
                }, 
                {
                    title: '持仓(手)',
                    dataIndex: 'position',
                    scopedSlots: {
                        customRender: 'position'
                    }
                },
                {
                    title: '持仓价格(元)',
                    dataIndex: 'price',
                    scopedSlots: {
                        customRender: 'price'
                    }
                },
                {
                    title: '当前价格(元)',
                    dataIndex: 'lastPrice',
                },
                {
                    title: '名义市值(元)',
                    dataIndex: 'marketVal',
                    scopedSlots: {
                        customRender: 'marketVal'
                    }
                },
                {
                    title: '删除',
                    scopedSlots: {
                        customRender: 'del'
                    }
                },
            ],
            currentFuture: '',
        }
    },
    mounted() {
        console.log('this.name', this.name);
        this.load();
    },
    methods: {
        save() {
            let etf = {
                position: this.etfPosition.position,
                price: this.etfPosition.price,
            };

            let options = this.optPositions.map(i => {
                return {
                    code: i.code,
                    position: i.position,
                    price: i.price,
                };
            });

            let futures = this.futurePositions.map(i => {
                return {
                    code: i.code,
                    position: i.position,
                    price: i.price,
                }
            });

            localStorage.setItem('etf'+this.name, JSON.stringify(etf));
            localStorage.setItem('options'+this.name, JSON.stringify(options));
            localStorage.setItem('futures'+this.name, JSON.stringify(futures));
        },
        load() {
            console.log('etf'+this.name);
            let etf = JSON.parse(localStorage.getItem('etf'+this.name));
            if (etf) {
                this.etfPosition = {
                    ...this.etfPosition,
                    ...etf,
                };
            }

            let options = JSON.parse(localStorage.getItem('options'+this.name));
            let futures = JSON.parse(localStorage.getItem('futures'+this.name));
            if (options) {
                this.optPositions = options;
            }

            if (futures) {
                this.futurePositions = futures;
                this.$emit("changeFutures", this.futurePositions.map(i => i.code));
            }
        },
        addOptPosition() {
            let rt = _.find(this.optPositions, i => i.code == this.seleted);
            if (!rt) {
                let item = this.dynamicData[this.seleted];
                this.optPositions.push({
                    code: this.seleted,
                    position: 0,
                    marketVal: 0,
                    price: item ? item.last : 0,
                });
            }
            this.save();
        },
        addFuturePosition() {
            if (this.currentFuture) {
                this.futurePositions.push({
                    code: this.currentFuture,
                    position: 0,
                    marketVal: 0,
                    price: 0,
                });
            }
            this.$emit("changeFutures", this.futurePositions.map(i => i.code));
            this.save();
        },

        delOptPosition(code) {
            this.optPositions = this.optPositions.filter(i => i.code != code);
            this.save();
        },
        handleOptPos(record, val) {
            this.optPositions = this.optPositions.map(i => {
                if (i.code == record.code) return {
                    ...i,
                    position: val,
                };

                return i;
            });
            this.save();
        },
        handleOptPrice(record, val) {
            this.optPositions = this.optPositions.map(i => {
                if (i.code == record.code) return {
                    ...i,
                    price: val,
                };

                return i;
            });
            this.save();
        },

        delFuturePosition(code) {
            this.futurePositions = this.futurePositions.filter(i => i.code != code);
            this.$emit("changeFutures", this.futurePositions.map(i => i.code));
            this.save();
        },
        handleFuturePos(record, val) {
            this.futurePositions = this.futurePositions.map(i => {
                if (i.code == record.code) return {
                    ...i,
                    position: val,
                };

                return i;
            });
            this.save();
        },
        handleFuturePrice(record, val) {
            this.futurePositions = this.futurePositions.map(i => {
                if (i.code == record.code) return {
                    ...i,
                    price: val,
                };

                return i;
            });
            this.save();
        },

        handleEtfPos(val) {
            this.etfPosition.position = val;
            let etf = this.dynamicData[this.etfCode];
            if (etf) {
                this.etfPosition.price = etf.last;
            }
            this.save();
        },
        handleEtfPrice(val) {
            this.etfPosition.price = val;
            this.save();
        },
    },
    computed: {
        optPositionsDynamic() {
            let contract_multiplier = 100;
            return this.optPositions.map(i => {
                let item = this.dynamicData[i.code];
                let spot = this.dynamicData[this.spotCode];
                // return i;
                if (item) {
                    return {
                        ...i,
                        lastPrice: item.last,
                        // delta: item.delta,
                        // marketVal: Math.round(item.delta * contract_multiplier * i.position * spot.last * 100)/100,
                        delta: item.py_delta,
                        marketVal: Math.round(item.py_delta * contract_multiplier * i.position * spot.last * 100)/100,
                    }
                }
                return i;
            });
        },
        futurePositionsDynamic() {
            let contract_multiplier = 200;
            return this.futurePositions.map(i => {
                let item = this.dynamicData[i.code];

                if (item) {
                    return {
                        ...i,
                        lastPrice: item.last,
                        marketVal: Math.round(i.position * item.last * contract_multiplier * 100)/100,
                    }
                }
                return i;
            });
        },
        etfMarketVal() {
            let etf = this.dynamicData[this.etfCode];

            let contract_multiplier = 100;
            return Math.round(etf.last*this.etfPosition.position* 1000*contract_multiplier)/1000;
        },
        etfLastPrice() {
            if (this.dynamicData[this.etfCode]) {
                return this.dynamicData[this.etfCode]['last'];
            }
            else {
                return null;
            }
        },
        optionMarketVal() {
            return _.sumBy(this.optPositionsDynamic, 'marketVal') + _.sumBy(this.futurePositionsDynamic, 'marketVal');
        }
    }
}
</script>