<template lang="pug">
.page
    a-drawer(title="目标持仓"
        @close="sendVisible=false"
        :destroyOnClose="true"
        :width="800"
        :visible="sendVisible || isTrading")
            OrdersView(:orders="newOrders" style="margin-bottom:40px")
            .drawer-button
                a-button(type="primary" 
                    :loading="isTrading"
                    @click="sendOrder") 
                    my-icon(type="icon-send" style="font-size:18px")
                    span 下单
                a-button(type="default" 
                    style="margin-left:20px"
                    @click="initOrder") 
                    my-icon(type="icon-send" style="font-size:18px")
                    span 同步仓位(不交易)

    a-drawer(title="详情"
        @close="detailVisible=false"
        :destroyOnClose="true"
        :width="800"
        :visible="detailVisible")
            OrdersView(:orders="detailOrders")
    
    a-modal(v-model="addAccountVisible"
        @close="addAccountVisible=false"
        @ok="submitAddAccount"
        title="新增交易账号")
        a-form(:label-col="labelCol" 
            :wrapper-col="wrapperCol")
            a-form-item(label="Strategy Id")
                a-input(v-model="newAccount.strategyId")
            a-form-item(label="Account")
                a-input(v-model="newAccount.account")
            a-form-item(label="Trade Address")
                a-input(v-model="newAccount.tradeAddress")

    a-modal(v-model="updateAccountVisible"
        @close="updateAccountVisible=false"
        @ok="submitUpAccount"
        title="修改交易账号")
        a-form(:label-col="labelCol" 
            :wrapper-col="wrapperCol")
            a-form-item(label="Strategy Id")
                a-input(v-model="editAccount.strategyId")
            a-form-item(label="Account")
                a-input(v-model="editAccount.account")
            a-form-item(label="Trade Address")
                a-input(v-model="editAccount.tradeAddress")

    a-tabs(@tabClick="refresh")
        a-tab-pane(tab="当前持仓" key="current")
            .page
                a-upload(name="orders" 
                    accept=".json"
                    :showUploadList="false"
                    :beforeUpload="beforeUpload"
                    :directory="true"
                    :multiple="false")
                    a-button(type="primary")
                        a-icon(type="upload") 
                        span 打开配置文件夹
                a-input-search(v-model="filterIns" 
                    style="max-width:200px;margin:0px 10px;"
                    placeholder="filter")
                OrderPositionList(:onlyDisplay="true" 
                    style="margin-top:20px"
                    :filter-ins="filterIns"
                    :positions="currentPosition")
        a-tab-pane(tab="历史记录" key="history")
            .page 
                a-table(:columns="historyColumns"
                    @change="handleHistoryCtl"
                    size="middle"
                    :pagination="historyCtl"
                    rowKey="_id"
                    :dataSource="historyItems")
                    a(@click="getHistoryDetail(record._id)" slot="detail" slot-scope="record") detail

                    template(slot="isreal" slot-scope="text,record") 
                        span {{text ? '真实下单' : '同步仓位'}}
        a-tab-pane(tab="账号信息" key="account")
            .page 
                a-button(type="primary"
                    @click="addAccountVisible=true" 
                    style="margin-bottom:10px")
                    a-icon(type="plus")
                    span 增加账号
                a-table(:columns="accountColumns"
                    :dataSource="accountItems"
                    :pagination="{pageSize:20}"
                    size="small")
                    template(slot="operate" slot-scope="record")
                        a-popconfirm(title="确认删除？" @confirm="submitDelAccount(record._id)")
                            a 
                                a-icon(type="delete")
                        a-divider(type="vertical")
                        a(@click="handleUpAccount(record)")
                            a-icon(type="edit")
                    account-link(:name="account" 
                        slot-scope="account, record"
                        slot="account")
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import OrdersView from '../components/OrdersView.vue';
import OrderPositionList from '../components/OrderPositionList.vue';
import MyIcon from '../components/MyIcon';
import AccountLink from '../links/Account.vue';

export default {
    data() {
        return {
            filterIns: "",
            labelCol: {
                xs: {span: 24},
                sm: {span: 5}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 12}
            },
            detailVisible: false,
            detailOrders: {},

            currentPosition: {},
            config: {},
            currentStrategyIds: [],
            sendVisible: false,
            addAccountVisible: false,
            updateAccountVisible: false,
            isTrading: false,
            accountItems: [],
            accountColumns: [
                {
                    title: 'StrategyId',
                    dataIndex: 'strategyId'
                },
                {
                    title: 'Account',
                    dataIndex: 'account',
                    scopedSlots: {
                        customRender: 'account'
                    }
                },
                {
                    title: 'Trade Address',
                    dataIndex: 'tradeAddress'
                },
                {
                    title: '操作',
                    scopedSlots: {
                        customRender: 'operate'
                    }
                }
            ],
            historyItems: [],
            historyCtl: {
                total: 0,
                current: 1,
                pageSize: 15
            },
            historyColumns: [
                {
                    title: '交易时间',
                    dataIndex: 'createdAt',
                    // sorter: true,
                },
                {
                    title: '交易人',
                    dataIndex: 'createdBy',
                },
                {
                    title: '类型',
                    dataIndex: 'isRealOrder',
                    key: 'isRealOrder',
                    scopedSlots: {
                        customRender: 'isreal'
                    }
                },
                {
                    title: '关联账号',
                    dataIndex: 'accounts'
                },
                {
                    title: '详情',
                    scopedSlots: {
                        customRender: 'detail'
                    }
                }
            ],
            editAccount: {
                strategyId: '',
                account: '',
                tradeAddress: ''
            },
            newAccount: {
                id: '',
                strategyId: '',
                account: '',
                tradeAddress: ''
            }
        };
    },
    mounted() {
        this.getCurrent();
        this.getAccount();
    },
    methods: {
        handleUpAccount(record) {
            this.updateAccountVisible = true;
            this.editAccount = {
                id: record._id,
                strategyId: record.strategyId,
                account: record.account,
                tradeAddress: record.tradeAddress
            };
        },
        handleHistoryCtl(pagination, filters, sorter) {
            console.log(pagination, sorter);
            this.historyCtl = {
                ...this.historyCtl,
                current: pagination.current
            };
            this.getHistory();
        },
        submitDelAccount(id) {
            this.$api.request('DELETE', 'onlineorder/account/'+id)
            .then(() => {
                this.getAccount();
            })
        },
        submitUpAccount() {
            this.$api.request('PUT', 'onlineorder/account/'+this.editAccount.id, this.editAccount)
            .then(() => {
                this.updateAccountVisible = false;
                this.getAccount();
            })
            .catch(() => {
                this.$message.error("增加账号失败");
            });
        },
        submitAddAccount() {
            this.$api.request('POST', 'onlineorder/account', this.newAccount)
            .then(() => {
                this.addAccountVisible = false;
                this.getAccount();
            })
            .catch(() => {
                this.$message.error("增加账号失败");
            });
        },
        refresh(tab) {
            if (tab == 'account') {
                this.getAccount();
            }
            else if (tab == 'current') {
                this.getCurrent();
            }
            else if (tab == 'history') {
                this.getHistory();
            }
        },
        sendOrder() {
            this.isTrading = true;
            this.$api.request('POST', 'onlineorder', this.newOrders)
                .then(() => {
                    this.getCurrent();
                    this.isTrading = false;
                    this.$message.success("交易回合完成")
                });
        },
        initOrder() {
            this.$api.request('POST', 'onlineorder/init', this.newOrders)
                .then(() => {
                    this.getCurrent();
                    this.$message.info("同步仓位完成");
                });
        },
        getCurrent() {
            this.$api.request('GET', 'onlineorder/current')
                .then(res => {
                    this.currentPosition = res.data;
                });
        },
        getAccount() {
            this.$api.request('GET', 'onlineorder/accounts')
                .then(res => {
                    this.currentStrategyIds = res.data.map(i => i.strategyId);
                    this.accountItems = res.data;
                });
        },
        getHistory() {
            this.$api.request('GET', 'onlineorder/missions', this.historyCtl)
                .then(res => {
                    this.historyItems = res.data.data.map(d => {
                        return {
                            ...d,
                            createdAt: moment(d.createdAt).format('lll'),
                            _id: d._id,
                            createdBy: d.createdBy && d.createdBy.name
                        };
                    });
                    this.historyCtl = {
                        ...this.historyCtl,
                        total: res.data.total
                    };
                });
        },
        getHistoryDetail(id) {
            this.$api.request('GET', 'onlineorder/detail/'+id)
                .then(res => {
                    this.detailOrders = res.data.newOrders;
                    this.detailVisible = true;
                });
        },
        async beforeUpload(file) {
            if(!(/\.txt$/.test(file.name))) return false;
            let fr = new FileReader();

            let p = new Promise((resolve, reject) => {
                fr.onload = () => {
                    resolve(fr.result);
                };
                fr.onerror = reject;
            });
            
            fr.readAsText(file);

            let config = await p;
            let c = {};
            let strategyId = file.name.replace(/\.txt$/, '');
            if (this.currentStrategyIds.includes(strategyId)) {
                c[strategyId] = config;
            }
            
            
            this.config = {
                ...this.config,
                ...c
            }
            this.sendVisible = true;
        }
    },
    computed: {
        newOrders() {
            return _.chain(this.config)
                .toPairs()
                .map(([key, val]) => {
                    let value = val.trim().split('\n')
                        .map(i => {
                            let j = i.trim().split(/\s+/);
                            console.log(j);
                            let instrument = j[0].trim();
                            let target = parseInt(j[1].trim());
                            let pItem = _.find(this.currentPosition[key], l => l.instrument == instrument);
                            return {
                                instrument,
                                target,
                                position: pItem ? pItem.position : 0, 
                            }
                        });
                    console.log(key, val);
                    return [key, value];
                })
                .fromPairs()
                .value();
        }
    },
    components: {
        OrdersView,
        MyIcon,
        AccountLink,
        OrderPositionList
    }
}
</script>

<style lang="less" scoped>

</style>