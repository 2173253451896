<template lang="pug">
    .task-pane(:style="{background: status=='running'?'#96dca8':'white'}")
        div(style="display:flex")
            .task-title 
                div {{command}}
            .task-control
                a-tooltip(title="启动"
                    v-if="status!='running'")   
                    a(@click="onStart")
                        a-icon(type="play-circle")
                a-tooltip(title="停止"
                    v-else)
                    a(@click="onStop")
                        a-icon(type="pause-circle")
                a-popconfirm(title="确定删除任务？"
                    v-if="status!='running'"
                    @confirm="submitDel")
                    a
                        a-icon(type="delete")
                a(@click="$emit('focus')")
                    a-icon(type="shrink")
        .task-content
            .task-output(ref="output")
                .std-item(v-for="(item, index) in outputs" 
                    :style="{color: item.type=='error'?'red':'white'}"
                    :key="index") 
                    span(style="color:#05a000") {{moment(item.timestamp).format('MM-DD HH:mm:ss')}}: 
                    span {{item.content}}
            .task-position 持仓
</template>

<script>
import moment from 'moment'
import {mapState} from 'vuex'

export default {
    props: ['type', 'args', 'taskId', 'path'],
    data() {
        return {
            moment,
        }
    },
    methods: {
        onStart() {
            this.$io.io.emit('startTask', this.taskId);
        },
        onStop() {
            this.$io.io.emit('stopTask', this.taskId);
        },
        submitDel() {
            this.$api.request('delete', 'worker/task/'+this.taskId)
            .then(() => {
                this.$emit('delete');
            });
        }
    },
    computed: {
        ...mapState('worker', ['taskOutputs', 'taskStatus']),
        outputs() {                        
            return this.taskOutputs[this.taskId];
        },
        status() {
            return this.taskStatus[this.taskId];
        },
        command() {
            return `${this.type=='python' ? 'python ': ''}${this.path} ${this.args}`
        }
    },
    watch: {
        outputs() {
            setTimeout(() => {
                var container = this.$refs.output;
                if (container ) {
                    container.scrollTop = container.scrollHeight;
                }
            }, 100);
        }
    }
}
</script>

<style lang="less" scoped>
.task-pane {
    
    .task-title {
        flex: 1;
    }

    .task-control {
        a {
            font-size: 20px;
            margin: 0 4px;
        }
    }
}


.task-output {
    height: 280px;
    background: black;
    color: white;
    overflow: auto;
    flex: 2;
    .std-item {
        white-space: pre-line;
        font-size: 10px;        
    }
}

.task-content {
    display: flex;
    flex-direction: row;
}

.task-position {
    flex: 1;
}
</style>