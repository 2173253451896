<template lang="pug">
div 
    a-row
        a-col(:span="18")
            .filter-pane(v-infinite-scroll="handleInfiniteOnLoad"
                :infinite-scroll-disabled="busy"
                :infinite-scroll-distance="10")
                a-list(:dataSource="news")
                    a-list-item(slot="renderItem" slot-scope="item, index")
                        a-list-item-meta(:description="item.datetime")
                            span(slot="title") {{ item.content }}

                .demo-loading-container(v-if="loading && !busy")
                    a-spin
        a-col(:span="6")
            z-table(:columns="columns")
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import infiniteScroll from 'vue-infinite-scroll';
export default {
    directives: { infiniteScroll },
    data() {
        return {
            news: [],
            loading: false,
            busy: false,
            columns: [
                {
                    title: '过滤字段',
                    dataIndex: 'word'
                },
                {
                    title: 'CreatedAt',
                    dataIndex: 'createdAt'
                }
            ]
        };
    },
    mounted() {
        // this.fetchData();
    },
    methods: {
        fetchData() {
            this.loading = true;
            axios.get(`/api/v3/news?skip=${this.news.length}`)
                .then(res => {
                    this.loading = false;
                    this.news =  _.concat(this.news, res.data);
                })
        },
        handleInfiniteOnLoad() {
            console.log('loading');

            this.fetchData();
        }
    }
}
</script>

<style>
.filter-pane {
    position: absolute;
    height: 100vh;
    overflow: auto;
    padding: 20px;
}
.demo-infinite-container {
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    overflow: auto;
    padding: 8px 24px;
    height: 300px;
}

.demo-loading-container {
    position: absolute;
    bottom: 40px;
    width: 100%;
    text-align: center;
}
</style>