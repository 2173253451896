<template lang="pug">
.page 
    a-drawer(:visible="detailVisible" 
        :title="detailTitle"
        :destroyOnClose="true"
        width="60%"
        @close="detailVisible=false")
        
        a-tabs(default-active-key="pnl")
            a-tab-pane(key="pnl" tab="PNL")
                a-spin(tip="loading" :spinning="pnlLoading")
                    chart(:option="detailOption" v-if="!isExternalDisplay")
                    a(@click="handleDownload") 下载
                    a-table(:columns="detailColumns" 
                        size="small"
                        :dataSource="detailItems"
                        v-if="!isExternalDisplay")

            a-tab-pane(key='pos' tab="Position")
                a-spin(tip="loading" :spinning="posLoading")
                    div 
                        span(style="margin:5px") 持仓收益 {{ posProfit }}
                        span(style="margin:5px") 持仓成本 {{ posCost }}
                    a-table(:columns="posColumns"
                        size="small"
                        :dataSource="posItems")
    z-table(:columns="columns" route="strategyMonitor" @link="handleLink")
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import Chart from '../components/Chart.vue';

export default {
    data() {
        return {
            pnlLoading: false,
            posLoading: false,
            detailVisible: false,
            detailTitle: '',
            detailOption: {},
            detailColumns: [
                {
                    title: '时间点',
                    dataIndex: 'time',
                },
                {
                    title: '市值',
                    dataIndex: 'market_value',
                },
                {
                    title: 'pnl',
                    dataIndex: 'pnl',
                }
            ],
            detailItems: [],
            posColumns: [
                {
                    title: '合约',
                    dataIndex: 'instrumentId',
                },
                {
                    title: '价格',
                    dataIndex: 'price',
                },
                {
                    title: '持仓',
                    dataIndex: 'TotalPosition',
                },
                {
                    title: '收益',
                    dataIndex: 'pnl',
                }
            ],
            posItems: [],
            posProfit: 0,
            posCost: 0,
            columns: [
                {
                    title: '策略名称',
                    dataIndex: 'name',
                    addAble: true,
                    editAble: true,
                    searchAble: true,
                    scopedSlots: { customRender: 'link' },
                },
                {
                    title: '地址',
                    dataIndex: 'address',
                    addAble: true,
                    editAble: true,
                    searchAble: true,
                    scopedSlots: { customRender: 'accountAddress' },
                },
                {
                    title: 'ClientID',
                    dataIndex: 'clientId',
                    addAble: true,
                    editAble: true,
                    searchAble: true,
                },
            ]
        };
    },
    methods: {
        handleDownload() {
            let content = this.detailItems.map(i => [i['time'], i['market_value'], i['pnl'], i['pnl'], i['000300.SH'], i['000852.SH'], i['000905.SH']].join(',')).join('\n');
            content = 'time,marketValue,pnl,000300.SH,000852.SH,000905.SH\n' + content;

            let blob = new Blob([content], {type: 'text/plain;charset=utf-8'});
            let url = URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = this.detailTitle + '.csv';
            document.body.appendChild(a);
            a.click();

            setTimeout(() => {
                URL.revokeObjectURL(url);
            }, 100);
        },
        async handleLink(record) {
            console.log(record);
            this.detailVisible = true;
            this.detailTitle = record.name;
            this.detailItems = [];
            this.posItems = [];
            this.getDetailData(record.address, record.clientId);
            this.getPositionData(record.address, record.clientId);
        },
        getPositionData(address, clientId) {
            this.posLoading = true;
            axios.post('/api/v3/position', {
                address,
                clientId
            }).then(res => {
                this.posLoading = false;
                this.posProfit = Math.round(_.sumBy(res.data, 'pnl'));
                this.posCost = Math.round(_.sumBy(res.data, 'TotalPosCost'));
                this.posItems = res.data;

            });
        },
        getDetailData(address, clientId) {
            this.pnlLoading = true;
            axios.post('/api/v3/pnl', {
                address,
                clientId
            }).then(res => {
                this.detailItems = res.data;
                this.detailOption = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross'
                        }
                    },
                    xAxis: {
                        type: 'category',
                        data: res.data.map(i => i.time)
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            type: 'line',
                            data: res.data.map(i => i.pnl)
                        }
                    ]
                }

                this.pnlLoading = false;
                
            })
        }
    },
    computed: {
        ...mapGetters({
            isExternalDisplay: 'user/isExternalDisplay'
        })
    },
    components: {
        Chart
    }
}
</script>