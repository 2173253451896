<template lang="pug">
    .page 
        chart(:option="option")
</template>

<script>
import Chart from './Chart.vue';

export default {
    props: ['symbol'],
    data() {
        return {
            items: {
                skew: [],
                vol: [],
                smile: [],
                time: [],
            },
            
        };
    },
    mounted() {
        this.sock = new WebSocket(`ws://${location.host}/api/v1/optionParam/live`);
        this.getHistory();
        
        this.sock.addEventListener('message', (event) => {
            this.updateChart(event.data);
        });

        this.sock.addEventListener('open', () => {
            this.sock.send(this.symbol);
        });
    },
    beforeUnmount() {
        this.sock.close();
    },
    methods: {
        updateChart(data) {
            data = JSON.parse(data);
            this.items.vol.push(data.vol);
            this.items.skew.push(data.skew);
            this.items.smile.push(data.smile);
            this.items.time.push(data.time);
        },
        getHistory() {
            this.$api.request('GET', 'optionParam/history', {symbol: this.symbol},'v1')
            .then(res => {
                this.items = res.data;
            });
        }
    },
    computed: {
        option() {
            return {
                title: {
                    text: this.symbol,
                    left: 'center'
                },
                tooltip: {
                    trigger: 'none',
                    axisPointer: {
                        type: 'cross'
                    }
                },
                legend: {
                    data: ['vol', 'skew', 'smile'],
                    left: 10,
                    orient: 'vertical'
                },
                grid: [
                    {height: '30%', top: '3%', width: '87%'},
                    {height: '30%', top: '33%', width: '87%'},
                    {height: '30%', bottom: '5%', width: '87%'},
                ],
                xAxis: [
                    {
                        gridIndex: 0,
                        type: 'category',
                        data: this.items.time,
                        axisLabel: {show: false}
                    },
                    {
                        gridIndex: 1,
                        type: 'category',
                        data: this.items.time,
                        axisLabel: {show: false}
                    },
                    {
                        gridIndex: 2,
                        type: 'category',
                        data: this.items.time,
                    },
                ],
                yAxis: [
                    {
                        gridIndex: 0,
                        type: 'value',
                        scale: true,
                        
                    },
                    {
                        gridIndex: 1,
                        type: 'value',
                        scale: true,
                    },
                    {
                        gridIndex: 2,
                        type: 'value',
                        scale: true
                    }
                ],
                series: [
                    {
                        data: this.items.vol,
                        type: 'line',
                        name: 'vol',
                        yAxisIndex: 0,
                        xAxisIndex: 0,
                    },
                    {
                        data: this.items.skew,
                        type: 'line',
                        name: 'skew',
                        yAxisIndex: 1,
                        xAxisIndex: 1,
                    },
                    {
                        data: this.items.smile,
                        type: 'line',
                        name: 'smile',
                        yAxisIndex: 2,
                        xAxisIndex: 2,
                    },
                ]
            };
        }
    },
    components: {
        Chart
    }    
}
</script>