const _ = require('lodash');


const state = {
    sendVisible: false,
    targetPositions: [],
};

const getters = {
    shouldSendVisible(state) {
        return state.sendVisible;
    }
};

const mutations = {
    addTargetPosition(state, value) {
        let targetPositions = state.targetPositions;
        if (!_.find(targetPositions, i => i.name == value.name)) {
            targetPositions.push(value);
        }
        
        state.targetPositions = _.clone(targetPositions);
        state.sendVisible = true;
    },
    rmTargetPosition(state, id) {
        let targetPositions = _.filter(state.targetPositions, i => i._id != id);
        state.targetPositions = _.clone(targetPositions);
        state.sendVisible = targetPositions.length > 0;
    },
    clearTargetPositions(state) {
        state.targetPositions = [];
        state.sendVisible = false;
    }
};

const actions = {

};

export default {
    state, 
    getters,
    actions,
    mutations,
    namespaced: true,
};