const _ = require('lodash');

const state = {
    taskOutputs: {},
    taskStatus: {},
    workerOnlines: {},
};

const getters = {};

const mutations = {
    addTaskOutput(state, item) {
        let outputs = _.clone(state.taskOutputs[item.taskId]) || [];
        outputs.push(item);

        if (outputs.length > 1000) {
            outputs.shift()
        }

        state.taskOutputs = {
            ...state.taskOutputs,
        };
        state.taskOutputs[item.taskId] = outputs;
    },
    setTaskStatus(state, s) {
        state.taskStatus = {
            ...state.taskStatus,
            ...s
        };
    },
    setWorkerOnlines(state, s) {
        state.workerOnlines = {
            ...state.workerOnlines,
            ...s
        };
    }
};

const actions = {

};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};