import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user'
import worker from './modules/worker'
import order from './modules/order'
import nav from './modules/nav'

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        user,
        worker,
        order,
        nav
    }
});