<template lang="pug">
.page 
    a-drawer(placement="right" 
        :visible="selectGroup.visible"
        @close="selectGroup.visible=false"
        width="45%"
        :destroyOnClose="true"
        :title="selectGroup.title")
        FactorGroup(:group="selectGroup.title")

    a-drawer(placement="right" 
        :visible="selectFactor.visible"
        @close="selectFactor.visible=false"
        width="45%"
        :destroyOnClose="true"
        :title="selectFactor.factorName")
        FactorDetail(:factorId="selectFactor.factorId")

    a-modal(v-model="newGroup.visible"
        @ok="submitAddGroup"
        title="添加因子组")
        a-form 
            a-form-item(label="名称")
                a-input(v-model="newGroup.name")
            a-form-item(label="类型")
                a-select(v-model="newGroup.instType")
                    a-select-option(value="stock") Stock 
                    a-select-option(value="cbond") Cbond 



    a-button(type="primary" style="margin:10px 0" @click="getData" ) 
        a-icon(type="redo")
        span 刷新

    a-button(type="primary" 
        style="margin:10px" 
        @click="newGroup.visible=true" ) 
        span 增加因子组
    
    a-button(type="primary"
        style="margin-right:10px"
        @click="exportCsv") 导出

    a-button(type="primary"
        style="margin-right:10px"
        @click="syncFactor") 同步因子

    a-input-search(placeholder="input search text" 
        style="width: 200px;" 
        v-model="search")

    
        
        
 
    .stat
        a-row 
            a-col(:span="6")
                a-statistic(:value-style="{color: rltColor('idle')}" 
                    title="未测" 
                    :value="idleCount")
            a-col(:span="6")
                a-statistic(:value-style="{color: rltColor('checking')}" 
                    title="测试中" 
                    :value="runningCount")
            a-col(:span="6")
                a-statistic(:value-style="{color: rltColor('pass')}" 
                    title="通过" 
                    :value="passCount")
            a-col(:span="6")
                a-statistic(:value-style="{color: rltColor('fail')}" 
                    title="失败" 
                    :value="computedItems.length - passCount - runningCount - idleCount")
    
    a-table(:dataSource="computedItems"
        size="small"
        rowKey="_id"
        :loading="loading"
        :pagination="{pageSize: 20, showTotal: (total) => `因子数 ${total}`}"
        :scroll="{ x: 1300 }"
        @change="handleTableChange"
        :columns="columns")

        template(slot="title")
            a-radio-group(default-value="all" 
                size="small"
                @change="handleGroup"
                button-style="solid")
                a-radio-button(value="all") All
                a-radio-button(v-for="g in groups" 
                    :key="g._id" 
                    :value="g.name") {{ g.name }}
            a-button(type="primary" 
                size="small"
                :disabled="selectGroup.title == 'all'"
                @click="selectGroup.visible = true"
                style="margin:0 20px") {{ selectGroup.title }} 详情

        template(slot="commit" slot-scope="msg,record")
            a-tooltip 
                span(slot="title") {{ msg }}
                span {{ msg.slice(0, 10) }}
        
        template(slot="checkMsg" slot-scope="msg,record")
            span(:style="{color: rltColor(msg)}") {{ msg }}
            
        template(slot="members" slot-scope="msg,record")
            div(v-for="m in msg" :key="m") {{ m }}

        template(slot="ic" slot-scope="msg,record")
            span {{msg.ic_mean}}

        template(slot="name" slot-scope="name, record")
            a(:href="relink(record.link)" 
                :style="{color: nameColor(record)}"
                target="_blank") {{ name }}

        template(slot="operate" slot-scope="record")
            a(@click="handleDetail(record)") 
                a-icon(type="edit")
            a-divider(type="vertical")
            a-popconfirm(title="不可恢复，确认删除？" @confirm="submitDel(record._id)")
                a
                    a-icon(type="delete" class="operate-icon")
        

</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import FactorGroup from './FactorGroup.vue';
import FactorDetail from './FactorDetail.vue';

export default {
    props: {
        type: {
            type: String,
            default: 'stock'
        },
        freq: {
            type: String,
            default: 'Daily'
        }
    },
    data() {
        return {
            newGroup: {
                visible: false,
                name: '',
                instType: 'stock',
            },
            selectGroup: {
                title: 'all',
                visible: false,
                factors: [],
            },
            selectFactor: {
                visible: false,
                factorId: '',
                factorName: '',
            },
            loading: false,
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    // fixed: 'left',
                    width: 50,
                    
                },
                {
                    title: '名称',
                    key: 'name',
                    dataIndex: 'name',
                    // fixed: 'left',
                    width: 250,
                    scopedSlots: { customRender: 'name' },
                    filters: [
                        { text: '需要Merge', value: 'merge' }, 
                        { text: '运行中', value: 'running' }, 
                    ],
                },
                
                {
                    title: 'Ver',
                    dataIndex: 'version',
                    width: 80,
                    // sorter: true,
                    filters: [
                        { text: 'v1', value: 1 },
                        { text: 'v2', value: 2 },
                        { text: 'v3', value: 3 },
                    ],
                },
                {
                    title: '测试Stock',
                    dataIndex: 'stockCheckMsg',
                    scopedSlots: { customRender: 'checkMsg' },
                    filters: [
                        { text: 'PASS', value: 'pass' }, 
                        { text: 'IDLE', value: 'idle' }, 
                        { text: 'RUNNING', value: 'checking' }, 
                        { text: 'Other', value: 'other' }
                    ],
                },
                {
                    title: '测试Cbond',
                    dataIndex: 'cbondCheckMsg',
                    scopedSlots: { customRender: 'checkMsg' },
                    filters: [
                        { text: 'PASS', value: 'pass' }, 
                        { text: 'IDLE', value: 'idle' }, 
                        { text: 'RUNNING', value: 'checking' }, 
                        { text: 'Fail', value: 'other' }
                    ],
                },
                {
                    title: '行情等级',
                    dataIndex: 'level',
                    filters: [
                        { text: 'Daily', value: 'Daily' },
                        { text: 'Minute', value: 'Minute' },
                        { text: 'Tick', value: 'Tick' },
                        { text: 'Level2', value: 'Level2' },
                    ],
                },
                {
                    title: 'IC-insample',
                    dataIndex: 'ic_in',
                    sorter: (a, b) => (a.ic_in.ic_mean || 0) - (b.ic_in.ic_mean || 0),
                    scopedSlots: { customRender: 'ic' },
                },
                {
                    title: 'IC-outsample',
                    dataIndex: 'ic_out',
                    sorter: (a, b) => (a.ic_out.ic_mean || 0) - (b.ic_out.ic_mean || 0),
                    scopedSlots: { customRender: 'ic' },
                },
                {
                    title: '成员',
                    dataIndex: 'members',
                    scopedSlots: { customRender: 'members' },
                },
                {
                    title: '最后提交者',
                    dataIndex: 'committer_name'
                },
                {
                    title: 'Commit',
                    dataIndex: 'message',
                    scopedSlots: { customRender: 'commit' },
                },                
                {
                    title: '更新时间',
                    dataIndex: 'updatedAt',
                    sorter: (a, b) => a.rowUpdatedAt - b.rowUpdatedAt,
                },
                {
                    title: '操作',
                    width: 80,
                    scopedSlots: { customRender: 'operate' },
                }
            ],
            items: [],
            filters: {},
            search: '',
            groups: [],
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        nameColor(record) {
            if (record.shouldMerge) {
                return '#ab3232';
            }
           
            else {
                return '#1890ff';
            }
        },
        relink(link) {
            if (location.host.includes('10.8.0.110')) {
                return link.replace('192.168.1.39', '10.8.0.110');
            }

            return link;
        },
        exportCsv() {
            let content = this.computedItems.map(item => {
                return [item.id, item.name, item.version, item.ic_in.ic_mean, item.ic_in.ic_std, item.ic_in.ic_ir, item.ic_in.rank_ic_mean, item.ic_in.rank_ic_ir, item.ic_in.rank_ic_std, item.ic_out.ic_mean, item.ic_out.ic_std, item.ic_out.ic_ir, item.ic_out.rank_ic_mean, item.ic_out.rank_ic_ir, item.ic_out.rank_ic_std].join(',')
            }).join('\n');
            content = 'id,name,version,insample_ic_mean,insample_ic_std,insample_ic_ir,insample_rank_ic_mean,insample_rank_ic_ir,insample_rank_ic_std,outsample_ic_mean,outsample_ic_std,outsample_ic_ir,outsample_rank_ic_mean,outsample_rank_ic_ir,outsample_rank_ic_std\n' + content;
            const file = new Blob([content], { type: 'text/plain' });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(file);
            link.download = "factor.csv";
            link.click();
            URL.revokeObjectURL(link.href);
        },
        handleDetail(record) {
            this.selectFactor.visible = true;
            this.selectFactor.factorId = record._id;
            this.selectFactor.factorName = record.name;
        },
        handleGroup(e) {
            this.selectGroup.title = e.target.value;
            
            if (e.target.value != 'all') {
                this.$api.request('GET', 'factorgroup/' + e.target.value, {}, 'task')
                    .then(res => {
                        this.selectGroup = {
                            ...this.selectGroup,
                            factors: res.data.factors.map(i => i.id)
                        }
                       
                    })
            }
        },
        rltColor(info) {
            if (info == 'pass') return '#7cdb7c';
            else if (info == 'idle') return 'gray';
            else if (info == 'checking') return 'blue';
            else return 'red';
        },
        handleTableChange(pagination, filters, sorter) {
            this.filters = filters;
        },
        reset(id) {
            this.$api.request('POST', 'factor/reset/'+id, {}, 'task')
                .then(res => {
                    this.getData();
                });
        },
        
        syncFactor() {
            this.loading = true;
            this.$api.request('POST', 'factor/sync', {}, 'task')
                .then(res => {
                    this.getData();
                });
        },
        submitAddGroup() {
            if (!this.newGroup.name) {
                return this.$message.warn('请输入因子名');
            }

            this.$api.request('POST', 'factorgroup', {
                name: this.newGroup.name,
                instType: this.newGroup.instType,
            }, 'task')
            .then(() => {
                this.newGroup.visible = false;
                this.getData();
            });
        },
        submitDel(id) {
            this.$api.request('DELETE', 'factor/'+id, {}, 'task')
            .then(() => {
                this.getData();
            });
        },
        getData() {
            this.loading = true;
            this.$api.request('GET', 'factors', {type: this.type}, 'task')
            .then(res => {
                this.items = res.data.map(d => ({
                    
                    ...d,
                    rowUpdatedAt: moment(d.updatedAt),
                    updatedAt: moment(d.updatedAt).format('ll'),
                    createdAt: moment(d.createdAt).format('ll')
                }));

                this.loading = false;
            });

            this.$api.request('GET', 'factorgroups', {}, 'task')
                .then(res => {
                    this.groups = res.data;
                });
        }
    },
    computed: {
        runningCount() {
            return this.computedItems.filter(item => item.stockCheckMsg == 'checking').length;
        },
        passCount() {
            return this.computedItems.filter(item => item.stockCheckMsg == 'pass').length;
        },
        idleCount() {
            return this.computedItems.filter(item => item.stockCheckMsg == 'idle').length;
        },
        computedItems() {
            return this.items.filter(item => {
                if (this.filters.version && this.filters.version.length) {
                    if (!this.filters.version.includes(item.version)) {
                        return false;
                    }
                }

                if (this.filters.level && this.filters.level.length) {
                    if (!this.filters.level.includes(item.level)) {
                        return false;
                    }
                }

                if (this.filters.name && this.filters.name.length) {
                    let c = 0;
                    if (this.filters.name.includes('merge') && item.shouldMerge) {
                        c += 1;
                    }

                    if (this.filters.name.includes('running') && item.isRunning) {
                        c += 1;
                    }

                    if (c == 0) return false;
                }

                if (this.filters.stockCheckMsg && this.filters.stockCheckMsg.length) {
                    let status = item.stockCheckMsg;
                    if (! ['pass', 'idle', 'checking'].includes(status)) status = 'other';

                    if (! this.filters.stockCheckMsg.includes(status)) {
                        return false;
                    }
                }

                if (this.filters.cbondCheckMsg && this.filters.cbondCheckMsg.length) {
                    let status = item.cbondCheckMsg;
                    if (! ['pass', 'idle', 'checking'].includes(status)) status = 'other';

                    if (! this.filters.cbondCheckMsg.includes(status)) {
                        return false;
                    }
                }

                if (this.search) {
                    let found = false;
                    let search = this.search.toLocaleLowerCase().trim();

                    if (item.name.toLocaleLowerCase().includes(search)) {
                        found = true;
                    }
                    else if (search == item.id){
                        found = true;
                    }
                    else if (item.message.toLocaleLowerCase().includes(search)) {
                        found = true;
                    }
                    else if (item.committer_name.toLocaleLowerCase().includes(search)) {
                        found = true;
                    }
                    else {
                        found = !!(item.members.find(i => i.toLocaleLowerCase().includes(search)));
                    }
                    
                    
                    if (!found) return false;

                }

                if (this.selectGroup.title != 'all') {
                    return this.selectGroup.factors.includes(item.id);
                    // return item.tags && item.tags.includes(this.selectGroup.title);
                }

                return true;
            });
        }
    },
    components: {
        FactorGroup,
        FactorDetail
    }
}
</script>

<style scoped>
.stat {
    width:300px;
    position:fixed;
    top: 40px;
    right:0;
    background:white;
    z-index:100;
    padding: 0 5px;
    border-radius: 2px;
}
.my-editor {
    /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
    background: #2d2d2d;
    color: #ccc;

    /* you must provide font-family font-size line-height. Example: */
    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5;
    padding: 5px;
    }

/* optional class for removing the outline */
.prism-editor__textarea:focus {
    outline: none;
    }
    .operate-icon {
    font-size: 14px;
    }
</style>
