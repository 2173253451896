<template lang="pug">
    .page 
        chart(:option="option")
</template>

<script>
import Chart from './Chart.vue';

export default {
    props: ['code0', 'code1', 'day'],
    components: {
        Chart
    },
    data() {
        return {
            x: [],
            sell: [],
            buy: [],
        }
    },
    mounted() {
       this.init();
    },
    methods: {
        init() {
            this.x = [];
            this.sell = [];
            this.buy = [];
            if (this.sock) {
                this.sock.close();
            }
            this.sock = new WebSocket(`ws://${location.host}/api/v1/ticks/spread`); 

            this.sock.addEventListener('message', (event) => {
                this.updateChart(JSON.parse(event.data));
            });

            this.sock.addEventListener('open', () => {
                this.sock.send(JSON.stringify({
                    code0: this.code0,
                    code1: this.code1,
                    tradingDay: this.day,
                }));
            });
        },
        updateChart(data) {
            data.map(d => {
                let [time, buy, sell] = d.split(',');
                this.x.push(time);
                this.buy.push(Number(buy));
                this.sell.push(Number(sell));
            })
        }
    },
    beforeUnmount() {
        this.sock.close();
    },
    watch: {
        day() {
            console.log('day', this.day);
            this.init();
        }
    },
    computed: {
        option() {
            return {
                title: {
                    text: `${this.code0} - ${this.code1}`,
                    left: 'center'
                },
                tooltip: {
                    // trigger: 'none',
                    axisPointer: {
                        type: 'cross'
                    }
                },
                xAxis: {
                    type: 'category',
                    data: this.x
                },
                yAxis: {
                    type: 'value',
                    scale: true
                },
                series: [
                    {
                        name: 'spread sell',
                        data: this.sell,
                        type: 'line',
                    },
                    {
                        name: 'spread buy',
                        data: this.buy,
                        type: 'line',
                    }
                ]
            };
        }
    }
}
</script>