<template lang="pug">
div 
    a-drawer(:visible="visible"
        :width="800"
        :title="editTitle"
        @close="visible=false"
        :destroyOnClose="true")
        a-spin(:spinning="loading")
            a-form(:label-col="{span: 12}" :wrapper-col="{span: 12}")
                a-row(:gutter="12")
                    a-col(:span="6")
                        a-form-item(label="bAutoBuy")
                            a-switch(v-model="params.bAutoBuy")
                    a-col(:span="6")
                        a-form-item(label="bAutoSell")
                            a-switch(v-model="params.bAutoSell")
                    a-col(:span="6")
                        a-form-item(label="bAutoQBuy")
                            a-switch(v-model="params.bAutoQBuy")
                    a-col(:span="6")
                        a-form-item(label="bAutoQSell")
                            a-switch(v-model="params.bAutoQSell")
                    a-col(:span="8")
                        a-form-item(label="bAutomation")
                            a-switch(v-model="params.bAutomation")

                    a-col(:span="8")
                        a-form-item(label="oBid")
                            a-input-number(style="width:100%"
                                v-model="params.oBid")
                    a-col(:span="8")
                        a-form-item(label="oAsk")
                            a-input-number(style="width:100%"
                                v-model="params.oAsk")
                    a-col(:span="8")
                        a-form-item(label="oQBid")
                            a-input-number(style="width:100%"
                                v-model="params.oQBid")
                    a-col(:span="8")
                        a-form-item(label="oQAsk")
                            a-input-number(style="width:100%"
                                v-model="params.oQAsk")
                    a-col(:span="8")
                        a-form-item(label="qFactor")
                            a-input-number(style="width:100%"
                                v-model="params.qFactor")
                    a-col(:span="8")
                        a-form-item(label="qAdj")
                            a-input-number(style="width:100%"
                                v-model="params.qAdj")
                    a-col(:span="8")
                        a-form-item(label="biasFactor")
                            a-input-number(style="width:100%"
                                v-model="params.biasFactor")


                    a-col(:span="8")
                        a-form-item(label="positionAdj")
                            a-input-number(:min="0" 
                                style="width:100%"
                                v-model="params.positionAdj"
                                :precision="0")
                    a-col(:span="8")
                        a-form-item(label="positionBase")
                            span {{params.positionBase}}
                    a-col(:span="8")
                        a-form-item(label="position")
                            span {{params.position}}

                    a-col(:span="8")
                        a-form-item(label="maxHittingSize")
                            a-input-number(:min="0" 
                                style="width:100%"
                                v-model="params.maxHittingSize"
                                :precision="0")
                    a-col(:span="8")
                        a-form-item(label="maxQuotingSize")
                            a-input-number(:min="0" 
                                style="width:100%"
                                v-model="params.maxQuotingSize"
                                :precision="0")
                    
                    a-col(:span="8")
                        a-form-item(label="maxBuyNumAdj")
                            a-input-number(:min="0" 
                                style="width:100%"
                                v-model="params.maxBuyNumAdj"
                                :precision="0")
                    a-col(:span="8")
                        a-form-item(label="maxBuyNumBase")
                            span {{params.maxBuyNumBase}}
                    a-col(:span="8")
                        a-form-item(label="maxBuyNum")
                            span {{params.maxBuyNum}}
                    a-col(:span="8")
                        a-form-item(label="maxSellNum")
                            span {{params.maxSellNum}}

                a-row(:gutter="18")
                    a-col(:span="6")
                        a-button(type="danger"
                            @click="submitStop"
                            style="width:100%") 停止交易
                    a-col(:span="6")
                        a-button(type="primary"
                            @click="getConfig"
                            style="width:100%") 刷新
                    a-col(:span="6")
                        a-button(type="primary"
                            @click="submitUpdateParam"
                            style="width:100%") 提交
    a-form(layout="horizontal" :label-col="{ span: 10 }" :wrapper-col="{ span: 14 }")
        a-row
            a-col(:span="4")
                a-form-item(label="maxHittingSize")
                    a-input-number(style="width:100%" size="small" v-model="stratConfig.maxHittingSize")

            a-col(:span="4")
                a-form-item(label="maxBuy")
                    a-input-number(style="width:100%" size="small" v-model="stratConfig.maxbuy")
    
            a-col(:span="4")
                a-form-item(label="PositionLimit")
                    a-input-number(style="width:100%" size="small" v-model="stratConfig.positionLimit")
                    
            a-col(:span="4")
                a-form-item(label="minmasize")
                    a-input-number(style="width:100%" size="small" v-model="stratConfig.minmasize")
        
        
    a-divider
    a-row
        a-col(:span="4" style="text-align:bottom;height:50px")
            a-input-search(v-model="filterIns" 
                style="max-width:200px;margin-top:20px"
                placeholder="filter")
        
        a-col(:span="4")
            a-statistic(title="Total Pnl" :value="total_pnl")
        a-col(:span="4")
            a-statistic(title="Total FAK Pnl" :value="total_pnl_fak")
        a-col(:span="2")
            a-statistic(title="合约总数" :value="instruments.length") 
        a-col(:span="2")
            a-statistic(title="看多数量" :value="num_long" :value-style="{ color: '#cf1322' }") 
        a-col(:span="2")
            a-statistic(title="看空数量" :value="num_short" :value-style="{ color: '#3f8600' }") 
        a-col(:span="2")
            a-statistic(title="看多资金" :value="num_long_market_value" :value-style="{ color: '#cf1322' }") 
        a-col(:span="2")
            a-statistic(title="看空资金" :value="num_short_market_value" :value-style="{ color: '#3f8600' }") 
    a-table(:dataSource="filteredInstruments"
        rowKey="name"
        :columns="columns"
        :pagination="{pageSize:20}"
        size="small")
        a(slot="name" 
            slot-scope="name,record") {{name}}

        span(slot="pnl" 
            :style="{color: pnl>0 ? 'red': 'green'}"
            slot-scope="pnl,record") {{pnl}}
        
        span(slot="fee" 
            slot-scope="fee,record") {{Math.round(fee*100)/100}}

        span(slot="status" 
            :style="{color: bAutomation ? 'green': 'gray'}"
            slot-scope="bAutomation,record") {{bAutomation ? "交易中" : "停止"}}

</template>

<script>
import axios from 'axios';

export default {
    props: ['port', 'account', 'download', 'activeKey', 'num'],
    data() {
        return {

            // host: '192.168.1.39:7001',
            // host: '10.8.0.154:7001',
            visible: false,
            editTitle: '',
            filterIns: '',
            // dataHost: '192.168.1.21:6000',
            // dataHost: `${location.hostname}:2${this.port}`,
            // dataHost: `192.168.1.39:2${this.port}`,
            columns: [
                {
                    title: '合约名',
                    dataIndex: 'name',
                    scopedSlots: {customRender: 'name'}
                },
                {
                    title: '价格',
                    dataIndex: 'price',
                },
                {
                    title: 'PNL',
                    dataIndex: 'pnl',
                    scopedSlots: {customRender: 'pnl'},
                    sorter: (a, b) => a.pnl - b.pnl
                },
                {
                    title: 'PNL FAK',
                    dataIndex: 'pnl_fak',
                    scopedSlots: {customRender: 'pnl'},
                    sorter: (a, b) => a.pnl_fak - b.pnl_fak
                },
                // {
                //     title: 'PNL LMT',
                //     dataIndex: 'pnl_lmt',
                //     scopedSlots: {customRender: 'pnl'},
                //     sorter: (a, b) => a.pnl_lmt - b.pnl_lmt
                // },
                {
                    title: '手续费',
                    dataIndex: 'fee',
                    scopedSlots: {customRender: 'fee'},
                    sorter: (a, b) => a.pnl_lmt - b.pnl_lmt
                },
                {
                    title: 'Base持仓',
                    dataIndex: 'basePosition',
                    sorter: (a, b) => a.basePosition - b.basePosition
                },
                {
                    title: '持仓',
                    dataIndex: 'position',
                    sorter: (a, b) => a.position - b.position
                },
                {
                    title: '状态',
                    dataIndex: 'bAutomation',
                    scopedSlots: {customRender: 'status'},
                    sorter: (a, b) => a.bAutomation - b.bAutomation
                },
                {
                    title: '时间',
                    dataIndex: 'time'
                }
            ],
            instruments: [],
            instrument: '',
            loading: false,
            params: {
                bAutoBuy: false,
                bAutoSell: false,
                bAutoQBuy: false,
                bAutoQSell: false,
                bAutomation: false,
            },
            stratConfig: {
                maxHittingSize: 0,
                maxQuotingSize: 0,
                maxbuy: 0,
                maxsell: 0,
                positionLimit: 0,
                marketSizeLimit: 0,
                pb: 1,
                err: 0.0001,
                nmc: 0,
                nma: 0,
                minmasize: 0,
                updatefactor: 0.002
            }
        };
    },
    mounted() {
        this.getStratConf();
        this.inter = setInterval(this.getInstruments, 1000);
        // this.getInstruments();
    },
    beforeDestroy() {
        clearInterval(this.inter);
    },
    methods: {
        handleEdit(record) {
            this.visible = true;
            this.editTitle = record.name;
            this.instrument = record.name;
            this.getConfig();
        },
        submitStop() {
            this.loading = true;
            axios.post(`/instruments/stop/${this.instrument}?cbond_num=${this.num}&cbond_port=${this.port}`, { withCredentials: false })
            .then(() => {
                this.getConfig();
            });
        },
        submitUpdateParam() {
            this.loading = true;
            axios.post(`/instruments/${this.instrument}?cbond_num=${this.num}&cbond_port=${this.port}`, this.params, { withCredentials: false })
            .then(() => {
                this.getConfig();
            });
        },
        getStratConf() {
            axios.get(`/config/strategy?cbond_num=${this.num}&cbond_port=${this.port}`, { withCredentials: false })
            .then(res => {
                this.stratConfig = res.data;
                // this.loading = false;
            })
            .catch(e => console.log(e));
        },
        setStratConf() {
            axios.post(`/config/strategy?cbond_num=${this.num}&cbond_port=${this.port}`, this.stratConfig, { withCredentials: false })
            .then(() => {
                this.getStratConf();
                // this.stratConfig = res.data;
                // this.loading = false;
            });
        },
        getConfig() {
            this.loading = true;
            axios.get(`/instruments/${this.instrument}?cbond_num=${this.num}&cbond_port=${this.port}`)
            .then(res => {
                this.params = res.data;
                this.loading = false;
            });
        },

        getInstruments() {
            if (this.activeKey == this.port) {
                axios.get(`/instruments?cbond_num=${this.num}&cbond_port=${this.port}`)
                .then(res => {
                    console.log(res.data);
                    this.instruments = res.data;
                })
                .catch(e => {
                    console.error(e);
                })
            }
            
        }
    },
    computed: {
        num_long_market_value() {
            let rt = 0
            this.instruments.forEach(ele => {
                if (ele.position > 0) rt += ele.position*ele.price;
            });
            return `${Math.round(rt/1000)/10}W`;
        },
        num_short_market_value() {
            let rt = 0
            this.instruments.forEach(ele => {
                if (ele.position < 0) rt -= ele.position*ele.price;
            });
            return `${Math.round(rt/1000)/10}W`;
        },
        num_long() {
            let rt = 0
            this.instruments.forEach(ele => {
                if (ele.position > 0) rt++;
            });
            return rt;
        },
        num_short() {
            let rt = 0
            this.instruments.forEach(ele => {
                if (ele.position < 0) rt++;
            });
            return rt;
        },
        total_pnl() {
            let pnl = 0;
            this.instruments.forEach(ele => {
                pnl += ele.pnl;
            });
            pnl = Math.round(pnl*1000)/1000;
            return pnl;
        },
        total_pnl_fak() {
            let pnl = 0;
            this.instruments.forEach(ele => {
                pnl += ele.pnl_fak;
            });
            pnl = Math.round(pnl*1000)/1000;
            return pnl;
        },
        total_pnl_lmt() {
            let pnl = 0;
            this.instruments.forEach(ele => {
                pnl += ele.pnl_lmt;
            });
            pnl = Math.round(pnl*1000)/1000;
            return pnl;
        },
        filteredInstruments() {
            return this.instruments.filter(i => {
                if (i.name.toUpperCase().includes(this.filterIns.toUpperCase())) {
                    return true;
                }
                return false;
            });
        }
    }
}
</script>

<style lang="less" scoped>
.ins-item {
    margin-top: 5px;

    .ins-item-label {
        display: inline;
    }
}

</style>