<template lang="pug">
.container
    a-input-search(v-model="fiterValue" style="width:200px;margin-bottom:5px")
    a-row 
        a-col(:sm="6" :xs="8" :md="4" :xl="3" v-for="item in items" :key="item[0]" )
            a-row.position-item
                a-col(:span="16")
                    span.position-label {{item[0]}}
                a-col(:span="8")
                    span.position-value {{item[1]}}

</template>

<script>
import _ from 'lodash';

export default {
    props: ['positions'],
    data() {
        return {
            fiterValue: ''
        };
    },
    methods: {

    },
    computed: {
        items() {
            return _.chain(this.positions)
             .toPairs()
             .sortBy(i => -i[1])
             .filter(i => {
                if(this.fiterValue) {
                    return i[0].includes(this.fiterValue) && i[1] != 0;
                }
                return i[1] != 0;
             })
             .value();
        }
    }
}
</script>

<style lang="less" scoped>
.container {
    margin: 0px;
}

.position-item {
    margin: 2px;
    border: solid rgb(180, 180, 180) 1px;
    border-radius: 3px;
}

.position-label {
    padding: 2px;
    width: 100%;
    display: inline-block;
    background: rgb(220, 220, 220);
    // color: red;
}
.position-value {
    display: inline-block;
    width: 100%;
    text-align: center;
    // margin: auto;
}
</style>