<template lang="pug">
.page
    a-modal(v-model="addSubGroupVisiable"
        title="新建文件夹"
        @ok="submitAddSubGroup")
        a-input(v-model="newSubGroup")

    h3.empty-folder(v-if="groups.length == 0") 文件夹为空，请联系管理员！
    a-tabs(v-else v-model="currentGroup")
        a-tab-pane(v-for="g in groups" 
            :tab="g.name" 
            :key="g.name")
            a-tabs(tab-position="right" 
                @edit="handleEdit" 
                type="editable-card")
                a-tab-pane(v-for="sub in g.subGroups"
                    :tab="sub"
                    :key="sub")
                    Folder(:group="g.name" :subGroup="sub" :subGroups="g.subGroups")
</template>
<script>
import {mapState} from 'vuex';
import Folder from '../components/folder/Folder.vue';

export default {
    data() {
        return {
            addSubGroupVisiable: false,
            newSubGroup: '',
            currentGroup: '',
            groups: []
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        submitAddSubGroup() {
            this.$api.request('POST', 'subGroup/add/' + this.currentGroup, {subGroup: this.newSubGroup}, 'fs')
                .then(res => {
                    this.getData();
                    this.addSubGroupVisiable = false;
                });
        },
        submitDelSubGroup(subGroup) {
            this.$api.request('POST', 'subGroup/del/' + this.currentGroup, {subGroup}, 'fs')
                .then(res => {
                    this.getData();
                });
        },
        getData() {
            this.$api.request('GET', 'groups/' + this.me._id, {}, 'fs')
                .then(res => {
                    this.groups = res.data;
                    if (!this.currentGroup && this.groups.length > 0) {
                        this.currentGroup = this.groups[0]['name'];
                    }
                });
        },  
        handleEdit(targetKey, action) {
            console.log(targetKey, action);
            if (action == 'add') this.addSubGroupVisiable = true;
            else if (action == 'remove') {
                this.$confirm({
                    title: '确定删除' + targetKey + '?',
                    onOk: () => {
                        this.submitDelSubGroup(targetKey);
                    }
                });
            }
        }
    },
    computed: {
        ...mapState('user', ['me'])
    },
    components: {
        Folder
    }
}
</script>

<style lang="css" scoped>
.empty-folder{
    display: block;
    margin: auto;
    width: 100%;
    text-align: center;
    font-size: 20px;
}
</style>