<template lang="pug">
.page 
    .ctl-bar 
        a-input-search(placeholder="search"
            v-model="filterValue"
            @search="getList"
            style="margin-bottom:10px;width:200px") 
        a-button(type="primary" style="margin-left:10px" @click="exportFile") 导出
    a-table(:columns="columns" 
        :rowKey="(record) => record._id"
        :pagination="{total, current, onChange:handlePageChange, pageSize:15}"
        size="small", 
        :dataSource="items")
</template>

<script>
import moment from 'moment';

export default {
    props: ['strategy', 'name'],
    data() {
        return {
            filterValue: '',
            total: 0,
            current: 1,
            items: [],
            columns: [
                {
                    title: 'Order Id',
                    dataIndex: 'orderId'
                },
                {
                    title: '合约',
                    dataIndex: 'instrument'
                },
                {
                    title: '价格',
                    dataIndex: 'price'
                },
                
                {
                    title: 'Direction',
                    dataIndex: 'direction'
                },
                {
                    title: 'Flag',
                    dataIndex: 'flag'
                },
                {
                    title: '类型',
                    dataIndex: 'orderType'
                },
                {
                    title: '报单量',
                    dataIndex: 'volume'
                },
                {
                    title: '成交量',
                    dataIndex: 'fillQty'
                },
                {
                    title: '撤单量',
                    dataIndex: 'cxlQty'
                },
                {
                    title: '错误量',
                    dataIndex: 'errQty'
                },
                
                {
                    title: '状态',
                    dataIndex: 'orderStatus'
                },
                {
                    title: '时间',
                    dataIndex: 'createdAt'
                }
            ]
        };
    },
    mounted() {
        this.getList();
    },
    methods: {
        exportFile() {
            this.$api.download(`strategy/${this.strategy}/exportOrders`, {}, 'order')
                .then(res => {
                    let url = window.URL.createObjectURL(new Blob([res.data], { type: 'text/csv' }));
                    let docUrl = document.createElement('a');
                    docUrl.href = url;
                    docUrl.setAttribute('download', `${this.name}_orders.csv`);
                    document.body.appendChild(docUrl);
                    docUrl.click();
                    window.URL.revokeObjectURL(url);
                    docUrl.remove();
                })
        },
        handlePageChange(page) {
            this.current = page;
            this.getList();
        },
        getList() {
            this.$api.request('GET', `strategy/${this.strategy}/orders`, {
                filterValue: this.filterValue.trim(),
                page: this.current-1,
                size: 15
            }, 'order')
                .then(res => {
                    let {total, data} = res.data;
                    this.items = data.map(d => ({
                        ...d,
                        price: Math.round(d.price*1000)/1000,
                        createdAt: moment(d.createdAt).format('lll')
                    }));
                    this.total = total;
                })
        }
    }
}
</script>