export const editAbleColumns = [
    {
        title: 'Client',
        dataIndex: 'client',
        type: 'Enum',
        enum: [
            '星光', '中金EQ', '中金财富', '广发香港', '申万香港', '国君UK', '华泰', '自有产品'
        ],
        width: 70,
        editAble: true,
        default: '星光'
    },
    {
        title: 'Status',
        dataIndex: 'status',
        type: 'EnumRadio',
        enum: ['on', 'off'],
        editAble: true,
        default: 'on',
        width: 60,
    },
    {
        title: 'Flag',
        dataIndex: 'flag',
        type: 'EnumRadio',
        enum: ['c', 'p'],
        editAble: true,
        default: 'c',
        width: 50,
    },
    {
        title: 'Side',
        dataIndex: 'side',
        type: 'EnumRadio',
        enum: ['short', 'long'],
        editAble: true,
        default: 'short',
        width: 60,
    },
    {
        title: 'Order Date',
        dataIndex: 'order_date', 
        width: 100,
        type: 'Date',
        editAble: true,
    },
    {
        title: 'Close Date',
        dataIndex: 'close_date', 
        width: 100,
        type: 'Date',
        editAble: true,
    },
    {
        title: 'Total Option Premium',
        dataIndex: 'total_premium', 
        type: 'Number',
        editAble: true,
    },
    {
        title: 'Strike Price',
        dataIndex: 'strike_price', 
        type: 'Number',
        default: 1,
        min: 0.001,
        editAble: true,
        percentDisplay: true,
    },
    {
        title: 'External Sell Price',
        dataIndex: 'ext_sell_price', 
        type: 'Number',
        min: 0.001,
        editAble: true,
        default: 0.001,
        percentDisplay: true,
    },
    {
        title: 'First Trade date',
        dataIndex: 'first_trade_date', 
        type: 'Date',
        width: 100,
        editAble: true,
    },
    {
        title: 'Expiry Date',
        dataIndex: 'expiry_date', 
        width: 100,
        type: 'Date',
        editAble: true,
    },
    {
        title: 'notional',
        dataIndex: 'notional', 
        type: 'Number',
        min: 0.001,
        editAble: true,
        default: 0.001
    },
    {
        title: 'Initial Price',
        dataIndex: 'init_price', 
        type: 'Number',
        min: 0.001,
        editAble: true,
        default: 0.001
    },
    {
        title: 'Hedging Threshold',
        dataIndex: 'hedging_threshold',
        type: 'Number',
        editAble: true,
    },
    {
        title: 'Max Delta Deviation',
        dataIndex: 'max_delta_deviation',
        type: 'Number',
        editAble: true,
    },
    {
        title: 'hedging vol',
        dataIndex: 'hedging_vol',
        type: 'Number',
        editAble: true,
    },
    {
        title: 'Trader Confidence',
        dataIndex: 'trader_confidence',
        type: 'Number',
        default: 1,
        editAble: true,
        percentDisplay: true,
    },
    {
        title: 'Set Volatility',
        dataIndex: 'set_vola',
        type: 'Number',
        editAble: true,
        percentDisplay: true,
    },
    {
        title: 'Auto Volatility',
        dataIndex: 'init_sigma',
        type: 'Number',
        percentDisplay: true,
    },
    {
        title: 'Use Set Volatility',
        dataIndex: 'use_set_vola',
        type: 'Boolean',
        editAble: true,
        default: false,
    },
    {
        title: 'Risk Free Rate',
        dataIndex: 'risk_free_rate',
        type: 'Number',
        editAble: true,
        percentDisplay: true,
    },
    {
        title: 'Dividend Yield(default=0)',
        dataIndex: 'dividend_yield',
        type: 'Number',
        editAble: true,
        percentDisplay: true,
    },
    {
        title: 'Current Stock Contract',
        dataIndex: 'curr_stock_contract',
        type: 'Number',
        // editAble: true,
    },
    {
        title: 'Cumulative Stock Pnl',
        dataIndex: 'cumulative_stock_pnl',
        type: 'Number',
        // editAble: true,
    },

];

export const SalesInputColumns = [
    {
        title: 'Stock Code',
        dataIndex: 'stock_code', 
        width: 90,
    },
    {
        title: 'Stock  Name',
        dataIndex: 'stock_name',
        width: 80,
    },
    {
        title: 'Price Variation',
        dataIndex: 'price_diff_pct',
        type: 'Number',
        percentDisplay: true,
    },
    {
        title: 'OpenPrice Variation',
        dataIndex: 'open_price_pct',
        type: 'Number',
        percentDisplay: true,
    },
    {
        title: 'SD Movement',
        dataIndex: 'sd_movement',
        type: 'Number',
    },
    {
        title: 'Period',
        dataIndex: 'period', 
    }
];

export const InitialDataColumns = [
    {
        title: 'Theoretical Price',
        dataIndex: 'theo_price',
        type: 'Number',
    },
    {
        title: 'Original Option Sales Price',
        dataIndex: 'orig_option_sales_price',
        type: 'Number',
    },
    {
        title: 'Strike Prices',
        dataIndex: 'strike_prices',
        type: 'Number',
    },
    {
        title: 'Option Contract',
        dataIndex: 'option_contract',
        type: 'Number',
    },
    {
        title: 'Original Option Theoretical Price',
        dataIndex: 'orig_option_theo_price',
        type: 'Number',
    },
];

export const CurrentPositionColumns = [
    {
        title: 'Remaining Period',
        dataIndex: 'remaining_period',

    },
    {
        title: 'DTE in Years',
        dataIndex: 'DTE_in_years',
        type: 'Number',
        width: 60,
    },
    {
        title: 'Current Stock Price',
        dataIndex: 'curr_stock_price',
        type: 'Number',
    },
    {
        title: 'Option Price',
        dataIndex: 'curr_theo_price',
        type: 'Number',
    },
    {
        title: 'Set Option Price',
        dataIndex: 'set_option_price',
        type: 'Number',
    },
    {
        title: 'Use Set Option Price',
        dataIndex: 'use_set_option_price',
        type: 'Boolean',
        // editAble: true,
        default: false,
    },
    {
        title: 'Time Value',
        dataIndex: 'time_value',
        type: 'Number',
    },
    {
        title: 'Instrinsic Value',
        dataIndex: 'instrinsic_value',
        type: 'Number',
    },
    {
        title: 'Option Delta',
        dataIndex: 'option_delta',
        type: 'Number',
    },
    {
        title: 'Gamma',
        dataIndex: 'gamma',
        type: 'Number',
    },
    {
        title: 'Theta',
        dataIndex: 'theta',
        type: 'Number',
    },
    {
        title: 'Vega',
        dataIndex: 'vega',
        type: 'Number',
    },
    {
        title: 'Rho',
        dataIndex: 'rho',
        type: 'Number',
    }
];

export const HedgingColumns = [
    {
        title: '% Delta Diff',
        dataIndex: 'delta_diff_pct',
        type: 'Number',
    },
    {
        title: 'Delta Diff',
        dataIndex: 'delta_diff',
        type: 'Number',
    },
    {
        title: 'Action?',
        dataIndex: 'action',
    },
    {
        title: 'Buy/Sell',
        dataIndex: 'buy_sell',
    },
    {
        title: 'Delta hedging',
        dataIndex: 'delta_hedging',
        type: 'Number',
    }
];

export const BookingColumns = [            
    {
        title: 'Hedging Gain/(Loss)',
        dataIndex: 'hedging_gain_loss',
        type: 'Number',
    },
    {
        title: 'Option Mark Up',
        dataIndex: 'option_mark_up',
        type: 'Number',
    },
    {
        title: 'Option Pnl',
        dataIndex: 'option_pnl',
        type: 'Number',
    },
    {
        title: 'Total Pnl',
        dataIndex: 'total_pnl',
        type: 'Number',
    },
    {
        title: 'Total Daily Pnl',
        dataIndex: 'total_daily_pnl',
        type: 'Number',
    },
    {
        title: 'Option Daily Pnl',
        dataIndex: 'option_daily_pnl',
        type: 'Number',
    },
    {
        title: 'Stock Daily Pnl',
        dataIndex: 'stock_daily_pnl',
        type: 'Number',
    }
];