<template lang="pug">
div
    a-popconfirm(@confirm="submitReset"
        ok-text="确定重置"
        title="重置后将会重新跑因子！！")
        a-button(type="danger") 重置

    a-popconfirm(@confirm="submitRetest"
        ok-text="重测"
        title="确定重测？")
        a-button(type="primary" style="margin: 0 10px") 重新测试

    a-table(:columns="rltColumns"
        size="small"
        rowKey="_id"
        :dataSource="rlts")
        template(slot="operate" slot-scope="record")
            a-button(type="danger" 
                size="small"
                @click="resetRlt(record._id)") 重置
</template>

<script>
export default {
    props: ['factorId'],
    data() {
        return {
            rlts: [],
            rltColumns: [
                {
                    title: 'InstType',
                    dataIndex: 'instType',
                },
                {
                    title: 'Frequency',
                    dataIndex: 'frequency',
                },
                {
                    title: '起始日期',
                    dataIndex: 'start_date',
                },
                {
                    title: '结束日期',
                    dataIndex: 'end_date',
                },
                {
                    title: 'reduce_cost',
                    dataIndex: 'reduce_cost',
                },
                {
                    title: '状态',
                    dataIndex: 'status',
                },
                {
                    title: '操作',
                    width: 80,
                    scopedSlots: { customRender: 'operate' },
                }
            ]
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        resetRlt(id) {
            this.$api.request('POST', `factorrlt/${id}/reset`, {}, 'task')
            .then(() => {
                this.getData();
            });
        },
        submitRetest() {
            this.$api.request('POST', `factor/${this.factorId}/retest`, {}, 'task')
            .then(() => {
                this.getData();
            });
        },
        submitReset() {
            this.$api.request('POST', `factor/${this.factorId}/reset`, {}, 'task')
            .then(() => {
                this.getData();
            });
        },
        getData() {
            this.$api.request('GET', `factor/${this.factorId}`, {}, 'task')
                .then(res => {
                    this.rlts = res.data.map(i => ({
                        ...i,
                        reduce_cost: Math.round(i.reduce_cost),
                    }))
                });
        }
    }
}
</script>